@use "../import/common" as g;

/* *
 *
 *  detail.css
 *
 *
 * */

/*--------------------------------------------------------------------------
 page-case-detail
---------------------------------------------------------------------------*/
.page-case-detail{
  &#page {
    padding-top: 0;
  }


  /*--------------------------------------------------------------------------
      overwrite
  ---------------------------------------------------------------------------*/
  .global-header{
    background: transparent;
    .global-nav__item > a{
    }
    &._fixed,
    &._hover{
      background: g.$color-black;
    }
  }
  @include g.media-sp {
    .global-header{
      &__inline{
        background: transparent;
      }
      &._fixed,
      &._hover{
        background: g.$color-black;
      }
    }
  }


  /*-------------------------------------------------------------------------
    .art-case
  ---------------------------------------------------------------------------*/
  .art-case{
    line-height: 2;
    font-size: 14px;
    &__kv{
      position: relative;
      .inner{
        padding-top: 172px;
      }
      .frame{
        position: relative;
        width: 1024px;
        margin: 0 auto;
        z-index: 2;
        img{
          width: 100%;
          height: auto;
        }
      }
      .video-wrap{
        overflow: hidden;
        position: absolute;
        // position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 900px;
        &:before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: g.$color-black;
          box-shadow: inset 0px -35px 35px -15px rgba(0, 0, 0, 0.6);
        }
        .video{
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          vertical-align: top;
          z-index: 1000;
          opacity: .07;
          line-height: 0;
          video{
            position: relative;
            width: 100%;
            height: auto;
            z-index: 1000;
          }
        }
      }
    }
    &__inner{
      position: relative;
      background: g.$color-white;
      z-index: 1;
      margin-top: -52px;
      padding-top: 52px;
    }
    &__overview{
      padding-top: 83px;
      .ttl-wrap{
        position: relative;
        display: inline-block;
      }
      .ttl{
        font-family: g.$tradeGothic;
        font-weight: bold;
        span{
          vertical-align: middle;
        }
        .inner{
          padding-right: 20px;
        }
        .link{
          display: inline-block;
          padding-top: 4px;
          a{
            display: inline-block;
            transition: all 400ms g.$ease-base;
            width: 20px;
            height: 20px;
            background: url('/assets/images/common/ico_link.svg') no-repeat 0 0;
            background-size: 20px auto;
            white-space: nowrap;
            text-indent: -9999px;
            &:hover{
              background: url('/assets/images/common/ico_link_blk.svg') no-repeat 0 0;
              background-size: 20px auto;
            }
          }
        }
      }
      .catch{
        margin-top: 10px;
        font-weight: bold;
        font-size: 32px;
        line-height: 1.5;
      }
      .lead{
        margin-top: 5px;
        font-size: 16px;
      }
      .category{
        margin-top: 70px;
        dl{
          margin-top: 5px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
        dt{
          width: 92px;
          margin-top: 5px;
          padding-right: 20px;
          font-family: g.$tradeGothic;
        }
        dd{
          width: calc(100% - 92px);

        }
        ul{
          @include g.inline-style();
        }
        li{
          margin-top: 5px;
          padding-right: 4px;
          font-size: 12px;
          a{
            transition: all 300ms g.$ease-base;
            display: inline-block;
            border-radius: 2px;
            padding: 3px 12px;
            background: #eee;
            color: g.$color-gray;
            &:hover{
              background: g.$color-black;
              color: #FFF;
            }
          }
        }
      }
    }
    &__img-w{
      margin-top: 120px;
      img{
        width: 100%;
        height: auto;
      }
    }
    &__info{
      margin-top: 110px;
      .box{
        .ttl{
          margin-bottom: 15px;
        }
      }
      .box-wrap{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
        .box{
          width: 50%;
          padding: 0 12px;
        }
      }
      .box-bg{
        margin-top: 55px;
        padding: 52px 18.7% 80px;
        background: #eee;
      }
    }
    &__cont{
      img{
        width: 100%;
        height: auto;
      }
      .img-box{
        margin-top: 120px;
        line-height: 0;
        .img{
          margin-top: 24px;
        }
        .video-wrap{
          &:before{
            content: "";
            display: block;
          }
          .video--portrait{
            position: relative;
            background: #FFF;
            &:before{
              display: block;
              content: "";
              padding-top: 56%;
            }
            .case-video{
              position: absolute;
              top: 0;
              left: 50%;
              width: auto;
              height: 100%;
              transform: translateX(-50%);
            }
          }
          .case-video{
            width: 100%;
            height: auto;
          }

        }
        .img-wrap{
          @include g.flex-style01();
          margin: 0 -12px;
          .img{
            width: 50%;
            padding: 0 12px;
          }
        }
        .caption{
          display: block;
          margin: 15px 0 15px;
          color: g.$color-gray;
        }
      }
      .txt-box{
        padding: 130px 18.7% 0;
      }
    }
    &__credit{
      margin-top: 112px;
      position: relative;
      padding: 39px 0 47px;
      &:before,
      &:after{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 80px;
        border-top: solid 2px g.$color-black;
      }
      &:before{ top: 0; }
      &:after{ bottom: 0; }
      .ttl{
        font-size: 16px;
        font-weight: normal;
      }
      dl dt{
        margin-top: 7px;
        line-height: 1.2;
      }
      ul{
        margin-top: 4px;
        font-size: 12px;
        font-family: g.$tradeGothic;
      }
    }
    &__share{
      margin-top: 60px;
      ul{
        @include g.inline-style();
      }
      li{
        padding-right: 28px;
        a{
          display: inline-block;
          vertical-align: middle;
          line-height: 20px;
        }
        i,img{
          vertical-align: middle;
        }
        i{
          font-size: 20px;
         }
        img{
          width: 17px;
          height: auto;
        }
      }
    }
    &__related{
      margin-top: 60px;
      .list{
        @include g.flex-style01();
        margin-top: 30px;
      }
      .item{
        width: 50%;
        a{
          display: block;
          position: relative;
          color: #FFF;
          &:before{
            transition: all 500ms g.$ease-base;
            @include g.pseudo();
            top: 0;
            left: 0;
            opacity: 0;
            background: rgba(0,0,0,0.4);
            z-index: 1;
          }
          &:hover{
            &:before{
              opacity: 1;
            }
          }
        }
        &--shadow{
          a:after{
            @include g.pseudo();
            top: 0;
            left: 0;
            box-shadow: inset 0px -187px 100px -100px rgba(0, 0, 0, 0.25);
          }
        }
      }
      .txt-wrap{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 40px 20px;
        line-height: 1.5;
        z-index: 2;
      }
      .label{
        font-size: 13px;
        font-family: g.$tradeGothic;
        font-weight: normal;
      }
      .catch{
        margin-top: 2px;
        font-size: 14px;
        font-weight: bold;
      }
      .img{
        display: block;
        overflow: hidden;
        img{
          width: 100%;
        }
      }
    }
  }
  @include g.media-pc-break {
    .art-case{
      &__kv{
        .inner{
          padding-top: 92px;
        }
        .frame{
          width: (100% - (g.$pc-space + g.$pc-space));
        }
        .video-wrap{
          height: calc(68vw + 92px);
        }
        #video{
          width: 100%;
        }
      }
    }
  }

  @include g.media-sp {
    .art-case{
      line-height: 1.78;
      &__kv{
        .inner{
          padding-top: 60px;
          margin-top: -0.5%;
        }
        .frame{
          width: 91%;
          z-index: 2;
        }
        .video-wrap{
          height: 85.5vw;
          &:before{
            opacity: 1;
            box-shadow: inset 0px -23px 30px -20px rgba(0,0,0,0.8);
          }
        }
      }
      &__inner{
        margin-top: -10px;
        padding-top: 10px;
      }
      &__overview{
        padding-top: 32px;
        .ttl{
          font-size: 16px;
        }
        .catch{
          margin-top: 12px;
          font-size: 18px;
        }
        .lead{
          margin-top: 7px;
          font-size: 14px;
        }
        .category{
          border-top: solid 1px #eee;
          margin-top: 23px;
          dl{
            margin-top: 25px;
          }
          dt{
            width: 100%;
            margin-top: 0;
            padding: 0;
            color: #ccc;
            font-weight: normal;
            font-size: 16px;
            line-height: 1.5;
          }
          dd{
            width: 100%;
          }
          li{
            margin-top: 4px;
            padding-right: 4px;
            a{
              padding: 3px 12px;
            }
          }
        }
      }
      &__img-w{
        margin-top: 30px;
      }
      &__info{
        margin-top: 0;
        .box{
          margin-top: 25px;
          padding: 30px 0 0;
          border-top: solid 1px #eee;
          .ttl{
            margin-bottom: 0;
            font-size: 16px;
            color: #ccc;
          }
        }
        .box-wrap{
          margin: 0;
          .box{
            width: 100%;
            padding: 25px 0 0;
            &:first-child{
              margin-top: 0;
              border-top: none;
            }
          }
        }
        .box-bg{
          background: none;
        }
      }
      &__cont{
        .img-box{
          margin-top: 25px;
          .img{
            margin-top: 5px;
          }
          .img-wrap{
            margin: 0;
            .img{
              width: 100%;
              padding: 0;
            }
          }
          .caption{
            margin: 10px 0 10px;//
            font-size: 12px;
            line-height: 1.5;
          }
          .img:last-child .caption{
            margin-bottom: 0;
          }
          .video-wrap{
            .video--portrait{
              &:before{
                content: none;
              }
              .case-video{
                position: static;
                transform: none;
                width: 100%;
                height: auto;
              }
            }
          }
        }
        .txt-box{
          padding: 30px 0 0;
        }
      }
      &__credit{
        margin-top: 44px;
        padding: 23px 0 26px;
        &:before,
        &:after{
          width: 60px;
        }
        ul{
          margin-top: 9px;
          font-size: 11px;
        }
        li{
          margin-top: 3px;
        }
        dl dt{
          margin-top: 5px;
        }
        dl dd ul{
          margin-top: 5px;
        }
      }
      &__share{
        margin-top: 30px;
        li{
          padding-right: 29px;
        }
      }
      &__related{
        margin-top: 65px;
        &__ttl{
          font-size: 16px;
        }
        .list{
          margin-top: 14px;
        }
        .item{
          margin-top: 0;
          width: 100%;
        }
        .txt-wrap{
          bottom: 8%;
          padding: 0 6.3%;
        }
        .label{
          line-height: 1.4;
          .inner{
            display: block;
            font-size: 18px;
          }
        }
        .catch{
          display: none;
          margin: 0;
        }
      }
    }
  }
}
