@use "../import/common" as g;

/* *
 *
 *  index.css
 *
 *
 * */
$clr-blk: g.$color-black;
$txtBaseDelay: 0.9s;
$txtNumDelay: 0.08s;
$baseDelay : 0.04s;
$stepDelay : 0.04s;
$baseBackDelay : 0.03s;

.mv,
.sec-culture01,
.sec-culture02,
.sec-culture03,
.sec-mission,
.sec-vision,
.sec-case {
  // display: none !important;
}

/*--------------------------------------------------------------------------
  sec
---------------------------------------------------------------------------*/

.page-vibes-index{
  &#page {
    padding-top: 0;
  }
  .global-header{
    position: absolute;
    &._clr01._fixed{
      background: none;
    }
    &._open{
      position: fixed;
    }
  }
  .cont-nav{
    position: fixed;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    ul{
      overflow: hidden;
      margin-top: -20px;
    }
    li{
      width: 100%;
      margin-top: 20px;
      font-size: 10px;

      a{
        display: block;
        position: relative;
        padding-right: 20px;
        color:  #ccc;
        text-align: right;
        padding-right: 0;
        .circle{
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          width: 4px;
          height: 4px;
          margin-top: -2px;
          background: #ccc;
          border-radius: 50%;
          right: -100%;
        }
        .txt{
          display: block;
          white-space: nowrap;
        }
      }
    }
  }
  .mv{
    position: relative;
    width: 100%;
    height: g.vw(3840, 2880);
    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      pointer-events: none;
      canvas {
        width: 100%;
        height: 100%;
      }
    }
    .txt1 {
      position: absolute;
      top: g.vw(409, 2880);
      left: g.vw(421, 2880);
      width: 0;
      opacity: 0;
      transform: translateX(g.vw(-4));
      transition: 1.2s g.$easeInOutExpo;
      overflow: hidden;
      transition-property: transform, opacity, width;
      &.is-show {
        @include g.media-pc {
          width: g.vw(355, 2880);
        }
        @include g.media-sp {
          width: g.vw(180);
        }
        opacity: 1;
        transform: translateX(0);
      }
      img {
        width: g.vw(355, 2880);
        max-width: none;
      }
    }
    .txt2 {
      position: absolute;
      top: g.vw(589, 2880);
      left: g.vw(387, 2880);
      letter-spacing: -0.25em;
      // width: g.vw(1756, 2880);
      @include g.media-sp {
        width: g.vw(643);
      }
      span {
        display: inline-block;
        img {
          max-width: none;
        }
      }
      .is-show {
        .character {
          span {
            width: 100%;
            transform: translateX(0);
            opacity: 1;
          }
        }
      }
      .character {
        span {
          opacity: 0;
          width: 0%;
          transform: translateX(g.vw(-6));
          overflow: hidden;
          transition: 1.3s g.$easeInOutExpo;
          transition-property: transform, opacity, width;
        }
        &1 {
          width: g.vw(449*.48, 2880);
          img {
            width: g.vw(449*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(449*.165);
            img {
              width: g.vw(449*.165);
            }
          }
        }
        &2 {
          span {
            transition-delay: .06s;
          }
          width: g.vw(394*.48, 2880);
          img {
            width: g.vw(394*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(394*.165);
            img {
              width: g.vw(394*.165);
            }
          }
        }
        &3 {
          width: g.vw(421*.48, 2880);
          span {
            transition-delay: .12s;
          }
          img {
            width: g.vw(421*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(421*.165);
            img {
              width: g.vw(421*.165);
            }
          }
        }
        &4 {
          span {
            transition-delay: .18s;
          }
          width: g.vw(421*.48, 2880);
          img {
            width: g.vw(421*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(421*.165);
            img {
              width: g.vw(421*.165);
            }
          }
        }
        &5 {
          width: g.vw(382*.48, 2880);
          img {
            width: g.vw(382*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(382*.165);
            img {
              width: g.vw(382*.165);
            }
          }
        }
        &6 {
          width: g.vw(482*.48, 2880);
          img {
            width: g.vw(482*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(482*.165);
            img {
              width: g.vw(482*.165);
            }
          }
        }
        &7 {
          span {
            transition-delay: .06s;
          }
          width: g.vw(446*.48, 2880);
          img {
            width: g.vw(446*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(446*.165);
            img {
              width: g.vw(446*.165);
            }
          }
        }
        &8 {
          span {
            transition-delay: .12s;
          }
          width: g.vw(519*.48, 2880);
          img {
            width: g.vw(519*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(519*.165);
            img {
              width: g.vw(519*.165);
            }
          }
        }
        &9 {
          span {
            transition-delay: .18s;
          }
          width: g.vw(483*.48, 2880);
          img {
            width: g.vw(483*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(483*.165);
            img {
              width: g.vw(483*.165);
            }
          }
        }
        &10 {
          width: g.vw(389*.48, 2880);
          img {
            width: g.vw(389*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(389*.165);
            img {
              width: g.vw(389*.165);
            }
          }
        }
        &11 {
          span {
            transition-delay: .06s;
          }
          width: g.vw(464*.48, 2880);
          img {
            width: g.vw(464*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(464*.165);
            img {
              width: g.vw(464*.165);
            }
          }
        }
        &12 {
          span {
            transition-delay: .12s;
          }
          width: g.vw(450*.48, 2880);
          img {
            width: g.vw(450*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(450*.165);
            img {
              width: g.vw(450*.165);
            }
          }
        }
        &13 {
          span {
            transition-delay: .18s;
          }
          width: g.vw(533*.48, 2880);
          img {
            width: g.vw(533*.48, 2880);
          }
          @include g.media-sp {
            width: g.vw(533*.165);
            img {
              width: g.vw(533*.165);
            }
          }
        }
      }
    }
    .word4, .word5, .word6 {
      margin-top: g.vw(84, 2880);
    }
    .txt3 {
      position: absolute;
      top: g.vw(1284, 2880);
      left: g.vw(421, 2880);
      width: g.vw(2091, 2880);
      opacity: 0;
      transform: translateX(g.vw(-10));
      transition: 1s g.$easeOutQuint;
      transition-property: transform, opacity;
      @include g.media-sp {
        width: 100%;
      }
      &.is-show {
        opacity: 1;
        transform: translateX(0);
      }
    }
    .ci {
      position: absolute;
      bottom: g.vw(130, 2880);
      left: g.vw(115, 2880);
      width: g.vw(2676, 2880);
      @include g.media-sp {
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }
  .sec{
    &__txt-wrap{
      .list{
        color: #888;
        margin-top: 24px;
        font-size: 14px;
        li{
          position: relative;
          margin-top: 7px;
          padding-left: 1em;
          &:before{
            display: inline-block;
            content: "・";
            position: absolute;
            top: 0;
            left: 0;
            width: 1em;
            height: 1em;
          }
        }
      }
      .txt{
        margin-top: 18px;
        line-height: 2;
      }
      .c-btn-style{
        margin-top: 32px;
        &:before{
          width: 385px;
          max-width: 100%;
        }
        a{
          width: 385px;
          max-width: 100%;
        }
      }
    }
    &__ttl{
      position: relative;
      &:before{
        content: "";
        display: block;
        position: absolute;
      }
      > span{
        display: block;
        line-height: 1.5;
      }
      .sub{
        font-size: 16px;
        .num{
          padding-right: 13px;
        }
      }
      .u-font-en{
        font-weight: normal;
      }
      .main{
        margin-top: 40px;
        font-size: 48px;
        font-weight: bold;
      }
    }
    &__img-box{
      display: flex;
      flex-direction: row-reverse;
      max-width: 1440px;
      margin: 0 auto;
      .sec__img{
        width: 50%;
      }
      .sec__txt-wrap{
        width: 50%;
        .inner{
          max-width: 720px;
        }
      }
    }
    &__img{
      overflow: hidden;
      width: g.vw(1440, 2880);
      height: g.vw(1440, 2880);
      max-width: 720px;
      max-height: 720px;
      img{
        display: block;
        position: relative;
        z-index: 1;
      }
      .inner{
        display: inline-block;
        position: relative;
        span{
          display: block;
          img{
            width: g.vw(1440, 2880);
            max-width: 720px;
          }
        }
      }
    }
  }
  .sec-culture01{
    padding: 160px 0 20px;
    background: #f5f5f5;
    .sec__ttl:before{
      top: -26px;
      left: 137px;
      width: 174px;
      height: 88px;
      background: url('/assets/images/vibes/culture01_bg_ttl.svg') no-repeat 0 0;
      background-size: 174px auto;
    }
    .sec__txt-wrap{
      padding-top: 175px;
      .inner{
        padding: 0 80px 0 130px;
      }
    }
  }
  .sec-culture02{
    padding: 500px 0 0;
    .sec__inner{
      padding: 720px 0 310px;
      background-color: #FFF;
    }
    .sec__ttl:before{
      top: -60px;
      left: 156px;
      width: 439px;
      height: 120px;
      background: url('/assets/images/vibes/culture02_bg_ttl.svg') no-repeat 0 0;
      background-size: 439px auto;
    }
    .sec__txt-wrap{
      position: relative;
      padding-top: 150px;
      .inner{
        max-width: 500px;
      }
    }
    .sec__img{
      margin: -1080px auto 0;
    }
    .bg{
      display: block;
      position: absolute;
    }
    .bg01{
      width: 350px;
      top: 240px;
      right: 40px;
      transition-property: transform;
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0, 1.27, 1, 1.01);
    }
    .bg02{
      top: 385px;
      width: 358px;
      right: -360px;
      transition-property: transform;
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0, 1.27, 1, 1.01);
    }
  }
  .sec-culture03{
    .sec__inner{
      padding: 170px 0 160px;
    }
    .sec__img{
      margin-top: -320px;
    }
     .sec__ttl:before{
       top: -43px;
       left: 114px;
       width: 327px;
       height: 96px;
       background: url('/assets/images/vibes/culture03_bg_ttl.svg') no-repeat 0 0;
       background-size: 327px auto;
     }
    .sec__txt-wrap{
      .inner{
        padding: 0 130px 0 128px;
      }
    }
  }
  .sec-photos{
    position: relative;
    padding-bottom: 105px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 79%;
      background: g.$color-black;
      z-index: -1;
    }
    .vibes-slider {
      // will-change: opacity;
      // opacity: 0;
      &__wrap {
        width: 720px;
        height: 720px;
        margin: 0 auto;
      }
      &__list {
        position: relative;
        li {
          will-change: transform, opacity;
          position: absolute;
          top: 0;
          left: 0;
          width: 720px;
          height: 720px;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          .cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            &:before {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #000;
              opacity: 0.3;
            }
            span {
              position: relative;
            }
          }
        }
      }
      &__num {
        margin-top: 64px;
        text-align: center;
        color: #888888;
        font-size: 15px;
      }
      img {
      }
    }
  
  }
  .sec-mission{
    padding: 143px 0 0;
    .sec__txt-wrap{
      margin-bottom: 160px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .sec__ttl:before{
        top: -42px;
        left: 71px;
        width: 321px;
        height: 94px;
        background: url('/assets/images/vibes/mission_bg_ttl.svg') no-repeat 0 0;
        background-size: 321px auto;
      }
    }
    .img-wrap{
      .img{
        overflow: hidden;
        position: relative;
        width: 100%;
        max-width: none;
        margin: 0 auto;
        min-width: 50vw;
        width: 50vw;
        min-height: 50vw;
        backface-visibility: hidden;
        img{
          max-width: none;
          width: 100%;
          height: auto;
        }
      }
      &._is-tween{
        .img{
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
    .list{
      margin: 0;
      display: flex;
      color: g.$color-black;
      font-size: 16px;
      li{
        margin-top: 5px;
      }
      ul + ul{
        margin-left: 40px;
      }
    }
    .member-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: -24px;
      margin-right: calc(-1% + -12px);
      margin-left: -12px;
      opacity: 0;
      transform: translateY(50px);
      transition-property: opacity, transform;
      transition-duration: 0.6s, 0.6s;
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), cubic-bezier(0.075, 0.82, 0.165, 1);
      transition-delay: 0.6s, 0.6s;
      &.sc-in {
        opacity: 1;
        transform: translateY(0);
      }
      li {
        position: relative;
        width: 33.3%;
        padding-top: 24px;
        padding-right: 12px;
        padding-left: 12px;
        @include g.media-sp {
          &:nth-child(2n){
            .img .inner {
              transition-delay: .2s;
              span, &::before {
                transition-delay: .2s;
              }
            }
          }
        }
        @include g.media-pc {
          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(8) {
            .img .inner {
              transition-delay: .2s;
              span, &::before {
                transition-delay: .2s;
              }
            }
          }
          &:nth-child(3),
          &:nth-child(6),
          &:nth-child(9) {
            .img .inner {
              transition-delay: .4s;
              span, &::before {
                transition-delay: .4s;
              }
            }
          }
        }
      }
      .txt {
        position: absolute;
        left: 0;
        bottom: 20px;
        width: 100%;
        // font-size: 12px;
        font-size: 15px;
        font-family: g.$tradeGothic;
        line-height: 1;
        text-align: center;
        color: #FFF;
        z-index: 20;
      }
      .img {
        display: block;
        position: relative;
        // shadow
        // &::after {
        //   content: '';
        //   display: block;
        //   position: absolute;
        //   top: 0; 
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   z-index: 10;
        //   box-shadow: inset 0px -90px 50px -50px rgb(0 0 0 / 25%);
        // }
        &.sc-in{
          .inner{
            span{
              width: 100%;
              transform: translate(0,0);
              transition-property: all;
              transition-duration: 1.3s;
              transition-timing-function: cubic-bezier(0.65,-0.01, 0.34, 1);
              transition-delay: 0.1s;
              img{
                transition-property: all;
                transition-duration: 1.3s;
                transition-timing-function: cubic-bezier(0.65,-0.01, 0.34, 1);
                transition-delay: 0.1s;
                transform: translateX(0);
              }
            }
            &:before{
              left: 0;
              width: 100%;
              transition-property: all;
              transition-duration: 0.8s;
              transition-timing-function: cubic-bezier(0.65,-0.01, 0.34, 1);
            }
          }
        }
      }
    }
  }
  .sec-vision{
    padding: 150px 0 160px;
    text-align: center;
    .sec__ttl{
      >span:nth-child(1){
        transition-delay: 0s;
      }
      >span:nth-child(2){
        transition-delay: 0.2s;
      }
      .lead {
        margin-top: 28px;
        img {
          width: 306px;
          height: 96px;
        }
      }
      .main{
        margin-top: 15px;
        transition-delay: 0.4s;
      }
    }
    .txt{
      margin-top: 20px;
      line-height: 2.2;
    }
  }
  .sec-case{
    padding: 105px 0;
    background: #FFF;
    .sec__ttl:before{
      top: -40px;
      left: 40px;
      width: 96px;
      height: 75px;
      background: url('/assets/images/vibes/case_bg_ttl.svg') no-repeat 0 0;
      background-size: 96px auto;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      li{
        width: 33.33%;
        margin-top: 60px;
        &:nth-child(-n+2){
          width: 50%;
          .img .inner img{
            // width: g.vw(1024, 2880);
            // width: 36.8vw;
            // max-width: 514px;
          }
        }
        a{
          display: block;
          &:hover{
            .img .inner:before{
              opacity: 1;
            }
          }
        }
        .img{
          position: relative;
          .inner{
            display: block;
            overflow: hidden;
            width: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            img{
              width: auto;
              height: 100%;
              max-width: none;
            }
          }
          .inner:before{
            transition-property: all;
            transition-duration: 0.3s;
            transition-timing-function: cubic-bezier(0.4, 0, 0.25, 1.01);
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0,0,0,0.4);
            opacity: 0;
            z-index: 1;
          }
        }
        .txt-wrap{
          margin-top: 15px;
          padding-right: 20px;
          h3{
            font-weight: normal;
            > span{
              display: block;
            }
            br{
              display: none;
            }
          }
          .txt{
            margin-top: 5px;
            font-family: g.$tradeGothic;
            font-size: 12px;
            color: #888;
          }
        }
      }
    }
    .c-btn-style{
      margin-top: 80px;
      transition-property: all;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition-delay: 0.6s;
      transform: translateY(50px);
      opacity: 0;
    }
  }
  @include g.media-pc{
    .global-nav__item._current > a > .inner:before{
      border-bottom-color: g.$color-black;
    }
  }
  @media screen and (max-width: 1440px){
    .sec-culture01{
      .sec__txt-wrap .inner{
        padding: 0 vw(80, 1440) 0 vw(130, 1440);
      }
    }
    .sec-culture03{
      .sec__txt-wrap .inner{
        padding: 0 vw(130, 1440) 0 vw(128, 1440);
      }
    }
    .sec-photos {
    }
  }
  @include g.media-pc-break{
    .sec-photos {
    }
  }
  @include g.media-sp {
    .global-header._clr01{
      &._clr01._fixed .global-header__inline{
        background: none;
      }
      &._clr01._fixed._open .global-header__inline{
        background: g.$color-black;
      }
    }
    .cont-nav{
      display: none;
    }
    .sec{
      &__ttl{
        .sub{
          .num{
            display: block;
            padding: 0 0 3px;
          }
        }
        .main{
          font-size: 24px;
        }
      }
      &__img-box{
        display: block;
        max-width: none;
        .sec__img{
          width: 92%;
        }
        .sec__txt-wrap{
          width: 100%;
          .inner{
            max-width: none;
          }
        }
      }
      &__img{
        position: absolute;
        width: 84%;
        max-width: none;
        height: g.vw(690);
        .inner{
          display: block;
          span img{
            width: g.vw(690);
            max-width: none;
          }
        }
      }
      &__txt-wrap{
        .list{
          margin-top: 28px;
          font-size: 12px;
          li{
            margin-top: 8px;
          }
        }
        .txt{
          margin-top: 4px;
          font-size: 14px;
        }
        .c-btn-style{
          margin-top: 35px;
          &:before{
            width: 100%;
          }
          a{
            width: 100%;
            >span{
              line-height: 21px;
            }
          }
        }

      }
    }
    .mv{
      height: g.vw(2403, 750);
      .txt1 {
        top: g.vw(230, 750);
        left: g.vw(60, 750);
        img {
          width: g.vw(177, 750);
        }
      }
      .txt2 {
        top: g.vw(320, 750);
        left: g.vw(49, 750);
        img {
          width: g.vw(642, 750);
        }
      }
      .txt3 {
        top: g.vw(570, 750);
        left: 0;
        padding-left: g.vw(60, 750);
        padding-right: g.vw(60, 750);
        font-size: g.vw(28, 750);
        font-weight: bold;
      }
    }
    .sec-culture01{
      position: relative;
      padding: 75px g.$sp-space 80px;
      .sec__ttl{
        &:before{
          top: -8px;
          left: 90px;
          width: 174px;
          height: 88px;
          background: url('/assets/images/vibes/culture01_bg_ttl.svg') no-repeat 0 0;
          background-size: 174px auto;
        }
        .main{
          margin-top: g.vw(880);
        }
      }
      .sec__txt-wrap{
        padding-top: 0;
        .inner{
          padding: 0 0 0 0;
        }
      }
      .sec__img{
        position: absolute;
        top: 187px;
        right: 0;
      }
    }
    .sec-culture02{
      position: relative;
      padding: 0;
      .sec__inner{
        padding: 68px 0 80px;
      }
      .sec__ttl{
        .main{
          margin-top: g.vw(790);
        }
        &:before{
          top: -40px;
          left: 134px;
          width: 439px;
          height: 120px;
          background-size: 439px auto;
        }
      }
      .sec__txt-wrap{
        padding-top: 0;
        .inner{
          max-width: none;
        }
      }
      .sec__img{
        top: 175px;
        left: 8%;
        margin: 0 auto 0;
        .inner span img{
          width: g.vw(630);
          height: g.vw(630);
        }
      }
      .bg{
        display: none;
      }
    }
    .sec-culture03{
      position: relative;
      .sec__inner{
        padding: 75px g.$sp-space 80px;
      }
      .sec__img{
        margin: 0 auto;
        top: 180px;
        left: 0;
      }
      .sec__ttl{
        &:before{
          top: -15px;
          left: 77px;
          width: 327px;
          height: 96px;
          background-size: 327px auto;
        }
        .main{
          margin-top: g.vw(866);
        }
      }
      .sec__txt-wrap{
        .inner{
          padding: 0;
        }
      }
    }
    .sec-photos{
      padding-bottom: 32px;
      &:before {
        height: 60%;
      }
      .vibes-slider {
        &__wrap {
          width: 315px;
          height: 315px;
        }
        &__list {
          li {
            width: 315px;
            height: 315px;
            .cover {
              font-size: 15px;
            }
          }
        }
        &__num {
          margin-top: 30px;
          font-size: 11px;
        }
      }
    }
    .sec-mission{
      padding: 73px 0 0;
      .l-inner-base {
        padding-right: 0;
        padding-left: 0;
      }
      .sec__txt-wrap{
        display: block;
        margin-bottom: 50px;
        .sec__ttl{
          text-align: center;
          &:before{
            top: 8px;
            left: calc(50% - 160px);
            width: 320px;
            height: 94px;
            background-size: 320px auto;
          }
          .main{
            margin-top: 70px;
          }
        }
      }
      .img-wrap{
        .img{
          width: 84%;
        }
      }
      .list{
        display: block;
        color: g.$color-black;
        text-align: center;
        font-size: 12px;
        li{
          margin-top: 3px;
          padding: 0;
          &:before{
            content: none;
          }
        }
        ul + ul{
          margin-left: 0;
        }
      }
      .member-list {
        margin: 0;
        li {
          position: relative;
          width: 50%;
          padding: 0;
        }
        .txt {
          bottom: 10px;
          font-size: 11px;
        }
      }
    }
    .sec-vision{
      padding: 75px 0 75px;
      text-align: center;
      .sec__ttl{
        .lead {
          margin-top: -17px;
        }
        .main{
          margin-top: -15px;
        }
      }
      .txt{
        margin-top: 15px;
        line-height: 1.71;
      }
    }
    .sec-case{
      padding: 75px 0;
      .sec__ttl{
        font-size: 16px;
        &:before{
          top: -40px;
          left: 40px;
        }
      }
      ul{
        display: block;
        margin-top: 55px;
        li{
          width: g.vw(630);
          margin-top: 0;
          a{
            display: block;
            position: relative;
            .img .inner:before{
              content: none;
            }
          }
          .img{
            width: g.vw(630);
            .inner img{
              width: g.vw(630);
              max-width: none;
            }
          }
          .txt-wrap{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            margin-top: 0;
            padding: 15px 6.34%;
            z-index: 1;
            h3{
              .catch{
                display: none;
              }
              .txt{
                margin-top: 5px;
                font-size: 13px;
                color: #FFF;
                .em{
                  display: block;
                  font-size: 18px;
                }
              }
            }
          }
        }
        li:nth-child(-n+2){
          width: g.vw(630);
          .img .inner img{
            width: g.vw(630);
            max-width: none;
          }
        }
      }
      .c-btn-style{
        margin-top: 60px;
      }
    }
  }
  

/*--------------------------------------------------------------------------
  animation
---------------------------------------------------------------------------*/
  &.sc-in{
    .global-header{
      transition-property: opacity, background;
      transition-duration: 0.4s, 0.3s;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1), cubic-bezier(0.455, 0.03, 0.515, 0.955);
      transition-delay: 1.7s, 0s;
      opacity: 1;
    }
    .cont-nav{
      opacity: 1;
      ul{
        transform: translateX(0);

      }
    }
  }
  .global-header{
    opacity: 0;
  }
  .cont-nav{
    opacity: 0;
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transition-delay: 1.7s;
    ul{
      transition-property: all;
      transition-duration: 0.4s;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      transition-delay: 1.7s;
      transform: translateX(100px);
      li{
        transition-property: all;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
        a{
          transition-property: all;
          transition-duration: 0.2s;
          transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
          span{
            transition-property: all;
            transition-duration: 0.2s;
            transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
          }
        }
        &:nth-child(7){
          transition-delay: 0s;
          a .circle{ transition-delay: 0s; }
          a .txt{ transition-delay: $stepDelay; }
        }
        &:nth-child(6){
          transition-delay: $baseBackDelay * 1;
          a .circle{ transition-delay: $baseBackDelay * 1; }
          a .txt{ transition-delay: $baseBackDelay * 1 + $stepDelay; }
        }
        &:nth-child(5){
          transition-delay: $baseBackDelay * 2;
          a .circle{ transition-delay: $baseBackDelay * 2; }
          a .txt{ transition-delay: $baseBackDelay * 2 + $stepDelay; }
        }
        &:nth-child(4){
          transition-delay: $baseBackDelay * 3;
          a .circle{ transition-delay: $baseBackDelay * 3; }
          a .txt{ transition-delay: $baseBackDelay * 3 + $stepDelay; }
        }
        &:nth-child(3){
          transition-delay: $baseBackDelay * 4;
          a .circle{ transition-delay: $baseBackDelay * 4; }
          a .txt{ transition-delay: $baseBackDelay * 4 + $stepDelay; }
        }
        &:nth-child(2){
          transition-delay: $baseBackDelay * 5;
          a .circle{ transition-delay: $baseBackDelay * 5; }
          a .txt{ transition-delay: $baseBackDelay * 5 + $stepDelay; }
        }
        &:nth-child(1){
          transition-delay: $baseBackDelay * 6;
          a .circle{ transition-delay: $baseBackDelay * 6; }
          a .txt{ transition-delay: $baseBackDelay * 6 + $stepDelay; }
        }
        &.is-active a,
        a:hover{
          transition-delay: 0s!important;
          color: #646464;
          .circle{
            transition-delay: 0s!important;
            background: #646464;
          }
          .txt{
            transition-delay: 0s!important;
          }
        }
      }
    }
   &.is-hover,
    &._fixed{
      li{
        transition-duration: 0.3s;
        a{
          transition-duration: 0.3s;
          width: 15px;
          span{
            transition-duration: 0.3s;
          }
        }
      }
      li{
        a{
          width: 100%;
        }
        a{
          .circle{
            right: 0;
          }
        }
        .txt{
          transform: translateX(100%);
        }
        &:nth-child(1){
          transition-delay: 0s;
          a .txt{ transition-delay: 0s; }
          a .circle{ transition-delay: $stepDelay; }
        }
        &:nth-child(2){
          transition-delay: $baseDelay * 1;
          a .txt{ transition-delay: $baseDelay * 1; }
          a .circle{ transition-delay: $baseDelay * 1 + $stepDelay; }
        }
        &:nth-child(3){
          transition-delay: $baseDelay * 2;
          a .txt{ transition-delay: $baseDelay * 2; }
          a .circle{ transition-delay: $baseDelay * 2 + $stepDelay; }
        }
        &:nth-child(4){
          transition-delay: $baseDelay * 3;
          a .txt{ transition-delay: $baseDelay * 3; }
          a .circle{ transition-delay: $baseDelay * 3 + $stepDelay; }
        }
        &:nth-child(5){
          transition-delay: $baseDelay * 4;
          a .txt{ transition-delay: $baseDelay * 4; }
          a .circle{ transition-delay: $baseDelay * 4 + $stepDelay; }
        }
        &:nth-child(6){
          transition-delay: $baseDelay * 5;
          a .txt{ transition-delay: $baseDelay * 5; }
          a .circle{ transition-delay: $baseDelay * 5 + $stepDelay; }
        }
        &:nth-child(7){
          transition-delay: $baseDelay * 6;
          a .txt{ transition-delay: $baseDelay * 6; }
          a .circle{ transition-delay: $baseDelay * 6 + $stepDelay; }
        }
      }
    }
  }
  .mv{
  }
  .sec{
    &__txt-wrap{
      .list,
      .txt,
      .c-btn-style{
        transition-property: opacity, transform, color;
        transition-duration: 0.6s, 0.6s, 0.3s;
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), cubic-bezier(0.075, 0.82, 0.165, 1), cubic-bezier(0.85, 0, 0.15, 1);
        transition-delay: 0.7s, 0.7s, 0s;
        transform: translateY(50px);
        opacity: 0;
        @include g.media-sp {
        }
      }
      &.sc-in{
        .list,
        .txt,
        .c-btn-style{
          transform: translateY(0);
          opacity: 1;
        }
      }
      &.sc-in .sec__ttl{
        &:before,
        > span{
          transform: translateY(0%);
          opacity: 1;
        }
      }
    }
    &__ttl{
      &:before{
        transform: translateY(50px);
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: 0.6s, 0.6s;
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-delay: 0.6s, 0.6s;
      }
      > span{
        transform: translateY(50px);
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: 0.6s, 0.6s;
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), cubic-bezier(0.075, 0.82, 0.165, 1);
        &:nth-child(1){transition-delay: 0.6s;}
        &:nth-child(2){transition-delay: 0.65s;}
      }
    }
    &__img{
      &.sc-in{
        .inner{
          span{
            opacity: 1;
            transform: translateY(0);
            @include g.media-sp {
              transition-delay: 0.4s;
            }
          }
        }
      }
      .inner{
        span{
          opacity: 0;
          transform: translateY(50px);
          transition-property: opacity, transform;
          transition-duration: 0.6s, 0.6s;
          transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }
  }
  .sec-vision{
    &.sc-in{
      .sec__ttl{
        &:before,
        span{
          transform: translateY(0%);
          opacity: 1;
        }
      }
      .txt{
        transform: translateY(0%);
        opacity: 1;
      }
    }
    .txt{
      transition-property: opacity, transform;
      transition-duration: 0.8s, 0.8s;
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), cubic-bezier(0.075, 0.82, 0.165, 1);
      transition-delay: 0.65s, 0.65s;
      transform: translateY(50px);
      opacity: 0;
    }
  }
  .sec-case{
    &.sc-in{
      .sec__ttl{
        &:before,
        span{
          opacity: 1;
          transform: translateY(0);
          transition-delay: 0s;
        }
      }
      ul li{
        opacity: 1;
        transform: translateY(0);
        $delay: 0.2s;
        &:nth-child(1) .img{
          &:before,
          .inner,
          .inner img{
          transition-delay: $delay * 0;
        }
        }
        &:nth-child(2) .img{
          &:before,
          .inner,
          .inner img{
            transition-delay: $delay * 1;
          }
        }
        &:nth-child(3) .img{
          &:before,
          .inner,
          .inner img{
            transition-delay: $delay * 0;
          }
        }
        &:nth-child(4) .img{
          &:before,
          .inner,
          .inner img{
            transition-delay: $delay * 1;
          }
        }
        &:nth-child(5) .img{
          &:before,
          .inner,
          .inner img{
            transition-delay: $delay * 2;
          }
        }
      .img{
          .inner{
            width: 100%;
            transform: translate(0,0);
            transition-property: all;
            transition-duration: 1.3s;
            transition-timing-function: cubic-bezier(0.65,-0.01, 0.34, 1);
            transition-delay: 0.1s;
            img{
              transition-property: all;
              transition-duration: 1.3s;
              transition-timing-function: cubic-bezier(0.65,-0.01, 0.34, 1);
              transition-delay: 0.1s;
              transform: translateX(0);
            }
          }
          &:before{
            left: 0;
            width: 100%;
            transition-property: all;
            transition-duration: 0.8s;
            transition-timing-function: cubic-bezier(0.65,-0.01, 0.34, 1);
          }
        }
      }
      .c-btn-style{
        opacity: 1;
        transform: translateY(0);
      }
    }
    ul{
      li{
        transition-property: opacity, transform;
        transition-duration: 0.4s, 0.8s;
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-delay: 0.6s, 0.6s;
        transform: translateY(50px);
        opacity: 0;
        .img{
          &:after{
            content: "";
            display: block;
            padding-top: 56.25%;
          }
          &:before{
            content: "";
          display: block;
            position: absolute;
            top: 0;
            left: 0;
          width: 100%;
            height: 100%;
            width: 0;
            background: g.$color-black;
          }
          .inner{
            img{
              transform: translateX(-8%);
            }
          }
        }
      }
    }
  }

  @include g.media-sp {
    .sec{
      &__ttl{
        &:before,
        > span{
          transition-delay: none;
          transition-delay: none;
        }
      }
    }




    .sec-case {
      $delay: 0.2s;
      &.sc-in{
        ul li{
          &:nth-child(1) .img{
            &:before,
            .inner,
            .inner img{
              transition-delay: $delay * 0;
            }
          }
          &:nth-child(2) .img{
            &:before,
            .inner,
            .inner img{
              transition-delay: $delay * 1;
            }
          }
          &:nth-child(3) .img{
            &:before,
            .inner,
            .inner img{
              transition-delay: $delay * 2;
            }
          }
          &:nth-child(4) .img{
            &:before,
            .inner,
            .inner img{
              transition-delay: $delay * 3;
            }
          }
          &:nth-child(5) .img{
            &:before,
            .inner,
            .inner img{
              transition-delay: $delay * 4;
            }
          }
        }
      }
    }
  }
}



@keyframes lineHoverNext {
  0% {
    right: 0;
    width: 50px;
  }
  50% {
    right: -50px;
    width: 0;
  }
  51% {
    right: 50px;
    width: 0;
  }
  100% {
    right: 0;
    width: 50px;
  }
}
@keyframes lineHoverPrev {
  0% {
    left: 0;
    width: 50px;
  }
  50% {
    left: -50px;
    width: 0;
  }
  51% {
    left: 50px;
    width: 0;
  }
  100% {
    left: 0;
    width: 50px;
  }
}
