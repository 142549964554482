/* *
 *
 *  variables
 *  変数
 *
 * */

/*--------------------------------------------------------------------------
   layout
---------------------------------------------------------------------------*/
/* @PC content width
-----------------------------------------------------------------*/
//defult
$pc-cont-max-width : 1024px;
//form
$pc-cont-small-width : 640px;
//article
$pc-cont-article-width : 752px;

/* space
-----------------------------------------------------------------*/
$pc-space : 2.8%;
$sp-space : 8%;


/*--------------------------------------------------------------------------
   breakpoint
---------------------------------------------------------------------------*/
/* @SP
-----------------------------------------------------------------*/
$sp : 768px;
$pc-break : 1280px;


/*--------------------------------------------------------------------------
   font-size
---------------------------------------------------------------------------*/
$font-size-default: 16px;
$font-size-default-sp: 14px;

$font-size-ttl: 21px;
$font-size-ttl-sp: 16px;

/*--------------------------------------------------------------------------
   animation
---------------------------------------------------------------------------*/
$ease-base: cubic-bezier(0.4, 0, 0.25, 1.01);
//cubic-bezier(0.6,-0.01, 0.26, 0.99)
$duration-base: 500ms;
$duration-btn-hover: 700ms;
$transitionHover: all 400ms cubic-bezier(0.8, 0, 0.2, 1);


// easing
$easeInSine:     cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine:    cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine:  cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad:     cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad:    cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad:  cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic:    cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic:   cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart:    cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart:   cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint:    cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint:   cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo:     cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo:    cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo:  cubic-bezier(1, 0, 0, 1);
$easeInCirc:     cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc:    cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc:  cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack:     cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack:    cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack:  cubic-bezier(0.68, -0.55, 0.265, 1.55);

/*--------------------------------------------------------------------------
   color
---------------------------------------------------------------------------*/
/* font color base */
$color-black: #1b1d20;
/* body color base */
$color-white: #f5f5f5;
/* bg color  */
$color-bg-gray: #eee;
/* font color  */
$color-red: #eb2929;
/* font color  */
$color-gray: #888;


/*--------------------------------------------------------------------------
   font-family
---------------------------------------------------------------------------*/
$tradeGothic: 'TradeGothicLT',"Yu Gothic", YuGothic ,"游ゴシック","ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
$YuGothic:  "Yu Gothic", YuGothic ,"游ゴシック", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
