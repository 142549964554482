@use "../import/common" as g;
/* *
 *
 *  index.css
 *
 *
 * */

$error-inner-width : 830px;
/*--------------------------------------------------------------------------
  page-404
---------------------------------------------------------------------------*/
.page-404{
  background: g.$color-black;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .inner-base{
    width: $error-inner-width;
    margin: auto;
  }
  .page-inner{
    width: 100%;
  }
  .sec{
    .sec-inner{
      padding-top: 14%;
      background: url(/assets/images/404/bg_obj.png) no-repeat 50% 0;
      background-size: 100% auto;
    }
    .txt{
      margin-top: 25px;
      color: #888;
      font-weight: bold;
    }
    .btn{
      margin-top: 25px;
      font-weight: bold;
      a{
        width: 245px;
        display: inline-block;
        position: relative;
        padding: 17px;
        border: solid 4px #888;
        color: #888;
        text-align: center;
        &:before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background-color: #888;
        }
        span{
          position: relative;
        }
        //btn color
        &:hover,
        &:active{
          &:before{
            @include g.animation(btnHoverBg g.$duration-btn-hover g.$ease-base);
          }
          @include g.animation(btnHoverTxtGray g.$duration-btn-hover g.$ease-base);
        }
      }
    }
  }
  .global-footer{
    background: g.$color-black;
    color: #888;
    a{
      color: #888;
    }
    &__logo{
      padding: 0;
       .img a{
        background: url(/assets/images/common/logo_gry.svg) no-repeat 0 0;
        background-size: 131px auto;
      }
    }
    &__address li{
      padding: 0 0.5em 0 0;

    }
    &__copy{
      text-align: left;
    }
  }
}

@media screen and (max-width: $error-inner-width + 256px){
  .page-404{
    .inner-base{
      width: 100%;
      padding: 0 g.$pc-space;
    }
  }
}

@media screen and (max-height: 600px){
  .page-404{
    position: static;

  }
}

@include g.media-sp {
  .page-404{
    // position: static;
    display: block;
    min-height: 600px;
    .inner-base{
      padding: 0 g.$sp-space;
    }
    .sec{
      .sec-inner{
        padding-top: 14%;
        background-size: 100% auto;
      }
      .txt{
        margin-top: 20px;
        color: #888;
        font-weight: normal;
      }
      .btn{
        margin-top: 30px;
        a{
          width: 100%;
          padding: 17px;
        }
      }
    }
    .global-footer{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      &__logo{
        padding: 0;
         .img a{
          background-size: 120px auto;
        }
      }
      &__address{
       line-height: 1.1;
        li{
          padding: 0 0.5em 0 0;
          &:first-child{
            width: 100%;
          }
        }
      }
    }
  }
}
