@use "../import/common" as g;

/* *
 *
 *  index.css
 *
 *
 * */

/*--------------------------------------------------------------------------
  page-capability-index
---------------------------------------------------------------------------*/

.page-capability-index{
  .sec{
    padding-bottom: 60px;
    border-bottom: solid 1px g.$color-black;
    .lead{
      p{
        margin-top: 32px;
        line-height: 2;
     }
    }
  }
}

@include g.media-sp {
  .page-capability-index{
    .sec{
      padding-bottom: 0;
      border-bottom: none;
      .lead{
        p{
          margin-top: 31px;
          line-height: 1.78;
        }
      }
    }
  }
}
