@use "./variables" as *;
@use 'sass:math';

/* *
 *
 *  mixin
 *  関数
 *
 * */


/*--------------------------------------------------------------------------
  media
---------------------------------------------------------------------------*/
@mixin media-pc {
  @media screen and (min-width: $sp){
    @content;
  }
}
@mixin media-pc-break {
  @media screen and (max-width: $pc-break){
    @content;
  }
}
@mixin media-sp {
  @media screen and (max-width: $sp){
    @content;
  }
}

/*--------------------------------------------------------------------------
    btn
---------------------------------------------------------------------------*/

@mixin btn-style($width:293px,$padding: 22px 30px, $isWhite: false,$icoSize: 26px,$space: 45px){
  position: relative;
  a{
    transition: $transitionHover;
    display: inline-block;
    position: relative;
    width: $width;
    padding: $padding;
    font-weight: bold;
    font-size: 14px;
    @if $isWhite == false {
      background-color: $color-black;
      color: #fff;
    } @else {
      background-color: #FFF;
      color: $color-black;
    }
     > span{
      display: block;
      position: relative;
      padding-right: $space;
      background-repeat: no-repeat;
      background-position: 100% 50%;
      background-size: $icoSize auto;
      line-height: 26px;
      z-index: 3;
    }
    svg.ico{
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: - #{math.div($icoSize , 2)};
      width: $icoSize;
      height: $icoSize;
      path,rect{
        transition: $transitionHover;
        transition-delay: 0;
      }
    }
  }
  &:before{
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: 8px;
    width: $width;
    height: 100%;
    background: rgba(0,0,0,0.1);
  }
  a:before{
    transform-origin: right;
    transition: $transitionHover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    @if $isWhite == false {
      background-color: #FFF;
    } @else{
      background-color: $color-black;
    }
  }
  //btn color
  a:hover,
  a:active{
    @if $isWhite == false {
      color: $color-black;
    } @else{
      color: $color-white;
    }
    &:before{
      width: 100%;
    }
    svg.ico{
      path{
       transition-delay: 150ms;
        @if $isWhite == false {
          fill: $color-black;
        } @else{
         fill: #FFF;
        }
      }
      rect{
        transition-delay: 150ms;
        @if $isWhite == false {
          stroke: $color-black;
        } @else{
          stroke: #FFF;
        }
      }
    }
  }
}

/*--------------------------------------------------------------------------
  flexbox
---------------------------------------------------------------------------*/
@mixin keyframes($animation-name: animaton) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
animation-fill-mode: forwards;
}
@include keyframes(btnHoverTxtWht) {
  0% {
   color: $color-white;
  }
  40%,60% {
   color: $color-black;
  }
  100% {
   color: $color-white;
  }
}
@include keyframes(btnHoverTxtBlk) {
  0% {
   color: $color-black;
  }
  40%,60% {
   color: $color-white;
  }
  100% {
   color: $color-black;
  }
}
@include keyframes(btnHoverTxtGray) {
  0% {
   color: $color-gray;
  }
  40%,60% {
   color: $color-black;
  }
  100% {
   color: $color-gray;
  }
}
@include keyframes(btnHoverBg) {
  // 0% {
  //   width: 0;
  //   left: 0;
  // }
  // 40%,60%{
  //   width: 100%;
  //   left: 0;
  // }
  // 100% {
  //   width: 0;
  //   left: 100%;
  // }
    0% {
      transform: scaleX(0);
      transform-origin: left;
    }
    // 49.9% {
    //   transform: scaleX(1);
    //   transform-origin: left;
    // }
    // 50% {
    //   transform: scaleX(1);
    //   transform-origin: right;
    // }
    100% {
      transform: scaleX(1);
      transform-origin: left;
    }
}
@include keyframes(btnHoverBg02) {


}
@include keyframes(btnHoverArwWht) {
  0% {
    background-image: url(/assets/images/common/arw_wht.svg);
  }
  40%,60% {
    background-image: url(/assets/images/common/arw_blk.svg);
  }
  100% {
    background-image: url(/assets/images/common/arw_wht.svg);
  }
}
@include keyframes(btnHoverArwBlk) {
  0% {
    background-image: url(/assets/images/common/arw_blk.svg);
  }
  40%,60% {
    background-image: url(/assets/images/common/arw_wht.svg);
  }
  100% {
    background-image: url(/assets/images/common/arw_blk.svg);
  }
}
@include keyframes(btnHoverArwGry) {
  0% {
    background-image: url(/assets/images/common/arw_gry.svg);
  }
  40%,60% {
    background-image: url(/assets/images/common/arw_wht.svg);
  }
  100% {
    background-image: url(/assets/images/common/arw_gry.svg);
  }
}


@include keyframes(btnHoverArwWht) {
  0% {
  }
  40%,60% {
    fill: $color-black;
  }
  100% {
  }
}
@include keyframes(btnHoverArwBlk) {
  0% {
  }
  40%,60% {
    fill: #FFF;
  }
  100% {
  }
}
@include keyframes(btnHoverArwGry) {
  0% {
  }
  40%,60% {
    fill: #FFF;
  }
  100% {
  }
}



@include keyframes(btnHoverArwCicleWht) {
  0% {
  }
  40%,60% {
    stroke: $color-black;
  }
  100% {
  }
}
@include keyframes(btnHoverArwCicleBlk) {
  0% {
  }
  40%,60% {
    stroke: #FFF;
  }
  100% {
  }
}
@include keyframes(btnHoverArwCicleGry) {
  0% {
  }
  40%,60% {
    stroke: #FFF;
  }
  100% {
  }
}



/*--------------------------------------------------------------------------
  pseudo
---------------------------------------------------------------------------*/
@mixin pseudo($width:100%,$height: 100%){
  content: "";
  display: block;
  position: absolute;
  width: $width;
  height: $height;
}


/*--------------------------------------------------------------------------
  px to XX
---------------------------------------------------------------------------*/
// math //================//
@function decimal-round ($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $mode == round {
    @return math.div(round($number * $n), $n);

  } @else if $mode == ceil {
    @return math.div(ceil($number * $n) , $n);

  } @else if $mode == floor {

    @return math.div(floor($number * $n), $n);
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    @return $number;
  }
}

// px to vw
@function vw($px, $parent_width: 750){
  // @return decimal-round( math.div( $px , $parent_width * 100vw ), 5);
  @return decimal-round( math.div(100, $parent_width) * $px , 4) + vw;
// px to %
}
@function per($px, $parent_width: 750){
  // @return decimal-round( math.div( $px , $parent_width * 100% ), 5);
  @return decimal-round(percentage(math.div($px, $parent_width)) , 4);

}



/*--------------------------------------------------------------------------
  flexbox
---------------------------------------------------------------------------*/
@mixin flex-style01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}


/*--------------------------------------------------------------------------
  inline-style
---------------------------------------------------------------------------*/
@mixin inline-style {
  letter-spacing: -0.4em;
  > li,
  > div,
  > p,
  > h1,> h2,> h3,> h4,> h5,
  > span,{
    display: inline-block;
    letter-spacing: normal;
  }
}
