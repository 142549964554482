@use "../import/common" as g;
/* *
 *
 *  blog index
 *
 *
 * */

/*--------------------------------------------------------------------------
  .sec-blog
---------------------------------------------------------------------------*/
.sec-blog-index{
  .lead{
    .catch{
      margin-top: 68px;
      font-size: 80px;
      font-weight: bold;
      font-family: g.$tradeGothic;
      line-height: 1.2;
    }
    .txt{
      margin-top: 0px;
    }
  }
  .c-blog-list{
    margin-top: -10px;
  }
}

@include g.media-sp {
  .sec-blog-index{
    .lead{
      .catch{
        margin-top: 55px;
        font-size: 49px;
        line-height: 1;
      }
      .txt{
        font-size: 14px;
        font-weight: normal;
        line-height: 2;
      }
    }
    .c-blog-list{
      margin-top: 0;
    }
  }
}
