@use "../import/common" as g;

/* *
 *
 *  global
 *  サイトのベーススタイル
 *
 * */

/*--------------------------------------------------------------------------w
   dev
---------------------------------------------------------------------------*/
#wpadminbar{
  display: none!important;
}
html.dev-pc{
  margin-top: 0!important;
}
@include g.media-sp {
  html.dev-sp{
    margin-top: 0!important;
  }
}

/*-------------------------------------------------------------------------w
   set
---------------------------------------------------------------------------*/

$header-h : 92px;
$fixed-header-h : 60px;
$header-h-sp : 60px;
$nav-tween-duration: 300ms;

/*--------------------------------------------------------------------------w
   body
---------------------------------------------------------------------------*/
html{
  overflow-y: scroll;
}


/*--------------------------------------------------------------------------
   head  common.util.isDisplayTypeのトリガー
---------------------------------------------------------------------------*/
head{
  font-family: pc;
}
/* @SP */
@include g.media-sp {
  head{
    font-family: sp;
  }
}


/*--------------------------------------------------------------------------w
   body
---------------------------------------------------------------------------*/

body {
  overflow: hidden;
  position: relative;
  background: g.$color-white;
  color: g.$color-black;
  font-family: g.$YuGothic;
  font-size: g.$font-size-default;
  line-height: 1.8;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* @SP */
@include g.media-sp {
  body {
    min-width: 320px;
    font-size: 14px;
    font-size: g.$font-size-default-sp;
    &._nav-open{
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }
}


/*--------------------------------------------------------------------------
   スマホ向けリセット
---------------------------------------------------------------------------*/
body{
  -webkit-text-size-adjust: 100%;
}


/*--------------------------------------------------------------------------
   base link
---------------------------------------------------------------------------*/
a{
  outline: none;
  color: g.$color-black;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}
a:link,
a:visited{
  text-decoration: none;
}


/*--------------------------------------------------------------------------
   img
---------------------------------------------------------------------------*/

img{
  max-width: 100%;
}


/*--------------------------------------------------------------------------
   other
---------------------------------------------------------------------------*/
/* clearfix
-----------------------------------------------------------------*/
.cf:before,
.cf:after {
  content: "";
  display: table;
}
.cf:after {
  clear: both;
}
.cf {
  zoom: 1;
}


/*--------------------------------------------------------------------------
   page
---------------------------------------------------------------------------*/

#page{
  transition: all 200ms g.$ease-base;
  padding-top: $header-h;
  ._nav-fixed &{
    padding-top: $fixed-header-h;
  }
}

@include g.media-sp {
  #page{
    padding-top: $header-h-sp;
  }
}


/*--------------------------------------------------------------------------
  loading-bg
---------------------------------------------------------------------------*/
#loading-bg{
  transition: all 200ms g.$ease-base;
  position: absolute;
  position: fixed;
  top: $header-h;
  left: 0;
  width: 100%;
  height: 100%;
  background: g.$color-white;
  z-index: 3000;
  opacity: 0;
  visibility: hidden;
  ._nav-fixed &{
    top: 0;
    top: $fixed-header-h;
  }
  &.landing{
    opacity: 1;
  }
  ._nav-fixed &{
    top: $fixed-header-h;
    span{
    top: $fixed-header-h;
    }
  }
}
@include g.media-sp {
  #loading-bg{
    top: $header-h-sp;
    ._nav-fixed &{
      top: $fixed-header-h;
    }
  }
}


/*--------------------------------------------------------------------------
   global-header
---------------------------------------------------------------------------*/
@include g.media-pc {
  .global-header{
    transition: all 400ms g.$ease-base;
    background: g.$color-black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    a{
      color: #FFF;
    }
    &__inner{
      transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      height: $header-h;
    }
    &__logo{
      transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      position: absolute;
      top: 35px;
      left: g.$pc-space;
      a{
        display: block;
        width: 100px;
        height: 18px;
        background: url("/assets/images/common/logo_wht.svg") no-repeat;
        background-size: 100px auto;
        text-indent: -9999px;
        overflow: hidden;
      }
    }
    &__contact{
      transition: top 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      position: absolute;
      top: 22px;
      right: g.$pc-space;
      font-family: g.$tradeGothic;
      a{
        transition: g.$transitionHover;
        display: block;
        width: 150px;
        height: 48px;
        font-weight: bold;
        text-align: center;
        line-height: 45px;
        font-size: 14px;
        border: solid 3px #FFF;
        span{
          position: relative;
          z-index: 1;
        }
        &:before{
          transition: g.$transitionHover;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background: #FFF;
        }
        &:hover,
        &:active{
          color: g.$color-black;
          &:before{
            width: 100%;
          }
        }
      }
    }

    /* _fixed */
    &._fixed{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      background: g.$color-black;
    }
    &._fixed &__inner{
      height: $fixed-header-h;
     }
    &._fixed &__logo{
      top: 21px;
      a{
        width: 108px;
        background-image: url("/assets/images/common/logo_wht.svg");
        background-size: 100px auto;
      }
    }
    &._fixed &__contact{
      top: 0;
      a{
        width: 180px;
        height: $fixed-header-h;
        line-height: $fixed-header-h - 3px;
        border-color: transparent;
        color: #FFF;
        &:hover{
          color: g.$color-black;
        }
      }
    }
  }
}

@include g.media-sp {
  .global-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    a{
      color: #FFF;
    }
    &__inner{
      height: $header-h-sp;
    }
    &__inline{
      transition: all 200ms g.$ease-base;
      padding: 23px g.$sp-space;
      background: g.$color-black;
    }
    &__logo{
      a{
        overflow: hidden;
        display: block;
        width: 80px;
        height: 14px;
        background: url("/assets/images/common/logo_wht.svg") no-repeat 0 0;
        background-size: 80px auto;
        text-indent: -9999px;
       }
    }
    /* __nav
    -----------------------------------------------------------------*/
    &__nav{
      overflow: hidden;
      display: none;
      background: g.$color-black;
      position: relative;
      z-index: 10;
    }
    /* __btn-nav
    -----------------------------------------------------------------*/
    &__btn-nav{
      position: absolute;
      top: 0;
      right: 5%;
      a{
        transition: all 200ms g.$ease-base;
        display: block;
        padding: 19px 11px;
      }
      span{
        transition: all 400ms g.$ease-base;
        display: block;
        margin: 6px 0;
        width: 20px;
        height: 2px;
        background: g.$color-white;
      }
    }
    /* 状態変化
    -----------------------------------------------------------------*/
    /* _fixed */
    &._fixed{
      // overflow: scroll;
    }
    /* _open */
    &._open{
      overflow-y: auto;
      height: 100%;
      background: g.$color-black!important;

       .global-header__btn-nav a{
         transform: rotate(45deg);
          span{
            background: #888!important;
            &:first-child{
              transform: translate(0, 4px);
            }
            &:last-child{
              transform: rotate(-90deg) translate(4px, 0);
            }
          }
       }
    }
    /* _open-lower */
    &._open{
      .global-header__logo{
        a{
          opacity: 0.05;
        }
      }
    }
  }
}


/*--------------------------------------------------------------------------
  header clr01
---------------------------------------------------------------------------*/
@include g.media-pc {
  .global-header._clr01{
    transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    background: none;
    &._hover{
      background: g.$color-black;
      .global-nav__item > a{
        color: g.$color-white;
      }
      .global-header__logo{
        a{
          background-image: url("/assets/images/common/logo_wht.svg");
        }
      }
      .global-header__contact a{
        color: g.$color-white;
      }
    }
    .global-nav__item > a{
      color: g.$color-black;
    }
    .global-header__logo{
      a{
        background-image: url("/assets/images/common/logo.svg");
      }
    }
    .global-header__contact{
      a{
        position: relative;
        border: solid 2px g.$color-black;
        color: g.$color-black;
        &:before{
          background: g.$color-black;
        }
        &:hover{
          color: #FFF;
//          @include g.animation(btnHoverTxtBlk 500ms g.$ease-base);
        }
        span{
          position: relative;
          z-index: 1;
        }
      }
    }
    &._fixed{
       background: g.$color-black;
       .global-nav__item > a{
        color: #FFF;
       }
      .global-header__logo a{
        background-image: url("/assets/images/common/logo_wht.svg");
      }
      .global-header__contact a{
        border: none;
        color: #FFF;
        &:before{
          background: #FFF;
        }
        &:hover{
          color:  g.$color-black;
//          @include g.animation(btnHoverTxtWht 500ms g.$ease-base);
        }
      }
    }
    /* global-nav */
    .global-nav__item > a:before{
      border-bottom-color: g.$color-black;
    }
    &._fixed .global-nav__item > a:before{
      border-bottom-color: g.$color-white;
    }
  }
}

@include g.media-sp {
  .global-header._clr01{
    .global-header__inline{
      background: none;
      padding: 31px g.$sp-space;
    }
    .global-header__btn-nav a{
      padding: 28px 11px;
      span{
        background: g.$color-black;
      }
    }
    .global-header__logo a{
        transition: width 150ms g.$ease-base,height 150ms g.$ease-base,background-size 150ms g.$ease-base;
        width: 100px;
        height: 17px;
        background-image: url("/assets/images/common/logo.svg");
        background-size: 100px auto;
    }
    &._fixed{
      .global-header__inline{
        padding: 23px g.$sp-space;
      }
      .global-header__btn-nav a{
        padding: 19px 11px;
      }
     .global-header__logo a{
        width: 80px;
        height: 14px;
        background-size: 80px auto;
      }
    }
    /* topăŽfixed */
    &._fixed,
    &._open{
      .global-header__inline{
        background: g.$color-black;
      }
      .global-header__logo a{
        background-image: url("/assets/images/common/logo_wht.svg");
      }
      .global-header__btn-nav a span{
        background: #FFF;
      }
    }
  }
}

/*--------------------------------------------------------------------------
   global-nav
---------------------------------------------------------------------------*/
@include g.media-pc {
  .global-nav{
    float: left;
    margin-left: 127px;
    &__list{
     @include g.inline-style();
    }
    &__item{
      transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      font-size: 14px;
      height: $header-h;
      line-height: $header-h;
      font-weight: bold;
      letter-spacing: 0.1em;
      font-family: g.$tradeGothic;
      > a{
        //transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
        display: block;
        position: relative;
        padding: 0 12px;
        > .inner:before{
          transition: all 300ms g.$ease-base;
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-bottom: solid 3px #FFF;
        }
      }
      &._current > a  > .inner:before{
        width: 100%;
      }
      &:hover > a{
        background: #16171a;
      }
      &.u-for-sp{
       display: none;
      }
    }
    &__item._open &__lower{
      visibility: visible;
      z-index: 6;
    }
    &__lower{
      overflow: hidden;
      position: absolute;
      top: $header-h;
      left: 0;
      width: 100%;
      height: 0;
      opacity: 0;
      // background: #16171a;
      z-index: 5;
      visibility: hidden;
      .nav-inner{
        padding-top: 24px;
        padding-bottom: 42px;
      }
      .lower-ttl{
        margin-top: 35px;
        font-size: 21px;
        font-family: g.$tradeGothic;
        font-size: 21px;
        color: g.$color-gray;
        line-height: 1;
      }
      ul{
        @include g.flex-style01();
        li{
          width: 50%;
          padding-right: 20px;
          a{
            transition: all 200ms g.$ease-base;
            color: g.$color-gray;
            &:hover{
              color: g.$color-white;
            }
          }
          // a{
          //   transition: all 200ms g.$ease-base;
          //   position: relative;
          //   color: g.$color-white;
          //   &:hover:before{
          //     width: 0;
          //   }
          //   &:before{
          //     transition: all 200ms g.$ease-base;
          //     content: "";
          //     position: absolute;
          //     top: 0;
          //     right: 0;
          //     width: 100%;
          //     height: 100%;
          //     display: block;
          //     background: #16171a;
          //   }
          // }
        }
      }
      .list{
        li{
          position: relative;
          height: 120px;
          @include g.flex-style01();
          a{
            width: 100%;
            vertical-align: middle;
          }
          &:before{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            border-top: solid 1px #242629;
          }
          &:nth-child(1):before,
          &:nth-child(2):before{
            content: none;
          }
        }
        span{
          display: block;
          line-height: 1.4;
        }
        .em{
          font-size: 21px;
          font-weight: bold;
        }
        .sub{
          margin-top: 5px;
          font-size: 13px;
        }
      }
      .list-img{
        padding: 22px 0 40px;
        li{
          margin-top: 20px;
        }
        a{
          display: flex;
          align-items: center;
        }
        span{
          display: block;
          font-weight: normal;
          line-height: 1.75;
        }
        .txt{
          padding-left: 10px;
        }
        .em{
          font-size: 16px;
        }
        .sub{
          font-size: 14px;
        }
        img{
          width: 120px;
          height: auto;
        }
      }
      .list-case{
        span{
          font-family: g.$tradeGothic;
        }
      }
      .list-blog{
        a:hover {
          .thumb-svg{
            opacity: 0;
          }
        }
        .img{
          transition: all 400ms g.$ease-base;
          position: relative;
          display: block;
        }
        .thumb-svg{
          transition: all 400ms g.$ease-base;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          image{
            display: block;
            height: auto;
            vertical-align: top;
            filter: url(#grayscale);
          }
        }
        .frame{
          display: block;
          width: 120px;
          // transform-origin: 50% 50%;
        }
        .date{
          font-size: 13px;
          span{
            display: inline-block;
            font-weight: bold;
          }
        }
        .ttl{
        }
      }
    }
    &__layer{
      position: absolute;
      top: $header-h;
      left: 0;
      width: 100%;
      height: 0;
      background: #16171a;
    }
  }
  .global-header._fixed{
    .global-nav{
      &__item{
        height: $fixed-header-h;
        line-height: $fixed-header-h;
      }
      &__lower,
      &__layer{
        top: $fixed-header-h;
      }
    }
  }
}

@keyframes gnavLowerHover{
  0% {
    width: 100%;
    left: 0;
    right: inherit;
  }
  49%{
    width: 49%;
    left: 0;
    right: inherit;
  }
  50%{
    width: 50%;
    left: inherit;
    right: 0;
  }
  100% {
    width: 0;
    left: inherit;
    right: 0;
  }
}

@media screen and (max-width: 1000px){




}

@include g.media-sp {
  .global-nav{
    /* _open */
    padding-bottom: 60px;
    &__item._open{
      transition: all 400ms g.$ease-base;
      -webkit-box-shadow: inset 0px 16px 16px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: inset 0px 16px 16px 0px rgba(0,0,0,0.2);
      box-shadow: inset 0px 16px 16px 0px rgba(0,0,0,0.2);
      background: #16171a;
      .global-nav__btn-lower a:after{
        opacity: 0;
      }
      .inner{
        padding-top: 26px;
        border-top: none;
      }
    }
    &__item{
      transition: all 400ms g.$ease-base;
      display: block;
      position: relative;
      height: auto;
      padding: 0;
      font-size: 20px;
      line-height: inherit;
      font-weight: normal;
      font-family: g.$tradeGothic;
      &._current-sp{
        > a span{
         color: rgba(255,255,255,0.3);
        }
      }
      > a{
        display: block;
        padding: 0 g.$sp-space;
      }
      .inner{
        display: block;
        position: relative;
        padding: 25px 0 19px;
        border-top: solid 1px rgba(204,204,204,0.05);
      }
      &:last-child .inner{
        border-bottom: solid 1px rgba(204,204,204,0.05);
      }
      &:not(.global-nav__item--has-lower){
        .inner:before{
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 7px;
          width: 6px;
          height: 10px;
          margin-top: -5px;
          background: url(/assets/images/common/arw_gry02.svg) no-repeat 0 0;
          background-size: 6px auto;
        }
      }
    }
    &__btn-lower{
      position: absolute;
      top: 0;
      right: g.$sp-space;
      width: 100px;
      height: 81px;
      z-index: 10;
      a{
        display: block;
        position: relative;
        right: -30%;
        width: 100%;
        height: 100%;
        background-size: 12px auto;
        &:before,
        &:after{
          transition: all 400ms g.$ease-base;
          content: "";
          display: block;
          position: absolute;
        }
        &:before{
          top: 50%;
          right: 30%;
          width: 13px;
          height: 0;
          border-top: solid 1px #888;
        }
        &:after{
          top: 50%;
          right: 30%;
          width: 0;
          height: 13px;
          border-right: solid 1px #888;
          margin: -6px 6px 0 0px;
        }
      }
    }
    &__lower{
      overflow: hidden;
      display: none;
      padding: 0 g.$sp-space;
      .nav-inner{
        padding: 0;
      }
      .lower-ttl{
        color: #FFF;
      }
      ul li{
        font-size: 14px;
        padding-right: 0;
        &._current-sp a,
        &._current-sp a span{
          color: rgba(255,255,255,0.3)!important;
        }
      }
      .list{
        padding-bottom: 10px;
        li{
          padding-bottom: 16px;
          a{
            display: block;
            position: relative;
            padding-left: 19px;
            &:before{
              content: "-";
              position: absolute;
              top: 0;
              left: 10px;
              margin-top: -1px;
            }
          }
          .sub{
            display: none;
          }
        }
      }
      .list-img{
        padding-bottom: 30px;
        li{
          margin-top: 5px;
        }
        a{
          @include g.flex-style01();
          width: 100%;
        }
        .txt,
        .img{
          vertical-align: middle;
          line-height: 1.2;
        }
        .img{
          width: 100px;
          img{
            width: 100%;
            height: auto;
          }
        }
        .txt{
          padding-left: 15px;
          width: calc(100% - 100px);
          span{
            display: block;
          }
          .em{
           font-size: 14px;
          }
          .sub{
            font-size: 11px;
            opacity: 0.1;
          }
        }
      }
      .list-blog{
        a:active {
          .thumb-svg{
            opacity: 0;
          }
        }
        .img{
          transition: all 400ms g.$ease-base;
          position: relative;
          overflow: hidden;
          display: block;
        }
        .thumb-svg{
          transition: all 400ms g.$ease-base;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          image{
            display: block;
            height: auto;
            vertical-align: top;
            filter: url(#grayscale);
          }
        }
        .frame{
          display: block;
          // transform-origin: 50% 50%;
        }
        .txt{
          font-size: 12px;
        }
        .date{
          font-size: 11px;
          color: g.$color-gray;
          span{
            display: inline-block;
          }
        }
        .ttl{
          margin-top: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 15px);
          white-space: nowrap;
        }
      }
    }
    &__sns{
      opacity: 0.1;
      margin-top: 38px;
      padding: 0 g.$sp-space;
      letter-spacing: -0.4em;
      li{
        display: inline-block;
        padding-right: 30px;
        letter-spacing: normal;
        a{

        }
        i{
          font-size: 20px;
        }
      }
    }
    &__info{
      margin-top: 30px;
      padding: 0 g.$sp-space;
      opacity: 0.1;
      color: #FFF;
      font-family: g.$tradeGothic;
      .logo{
        span{
          display: block;
        }
        .img{
          width: 165px;
          height: auto;
        }
        .txt{
          margin-top: 8px;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .address{
        overflow: hidden;
        margin-top: 10px;
        ul{
          @include g.inline-style();
          line-height: 1.59;
          margin-left: -14px;
        }
        li{
          padding-left: 6px;
          font-size: 11px;
          font-weight: bold;
          &:before{
            content: "|";
            padding-right: 0.5em;
          }
          &:first-child{
            width: 100%;
          }
          i{
            padding-left: 4px;
          }
        }
      }
      .copy{
        margin-top: 21px;
        font-weight: bold;
        font-size: 11px;
      }
    }
    &__layer{
      display: none;
    }
  }
  .global-header._fixed{
    .global-nav{
    &__item{
        padding: 0;
        height: auto;
        line-height: inherit;
      }
    }
  }
}

/*--------------------------------------------------------------------------
  global-footer
---------------------------------------------------------------------------*/
.global-footer{
  position: relative;
  padding: 40px 0;
  z-index: 1;
  background-color: g.$color-white;
  a{
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
  }
  .filter-svg{
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
  /* global-footer--s
  ---------------------------------------------------------------------------*/
  &--s{
    padding-top: 0;
  }
  &--s &__bt{
    border-top: solid 1px #ccc;
    padding-top: 18px;
    height: auto;
  }
  /* news
  ---------------------------------------------------------------------------*/
  &__news{
    a{
      transition: g.$transitionHover;
      display: block;
      position: relative;
      background: #FFF;
      padding: 0 40px;
      &:before{
        transition: g.$transitionHover;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: g.$color-black;
      }
      &:hover{
        color: #FFF;
      //  @include g.animation(btnHoverTxtBlk $duration-btn-hover g.$ease-base);
        &:before{
          width: 100%;
        //  @include g.animation(btnHoverBg $duration-btn-hover g.$ease-base);
        }
        svg.ico{
          path{
             fill: #FFF;
           // @include g.animation(btnHoverArwGry $duration-btn-hover g.$ease-base);
          }
          rect{
            stroke: #FFF;
           // @include g.animation(btnHoverArwCicleGry $duration-btn-hover g.$ease-base);
          }
        }
      }
    }
    svg.ico{
      position: absolute;
      top: 50%;
      right: 40px;
      margin-top: -15px;
      width: 30px;
      height: 30px;
      path,
      rect{
        transition: g.$transitionHover;
      }
    }
    dl{
      @include g.flex-style01;
      height: 92px;
      position: relative;
      justify-content: flex-start;
      font-weight: bold;
    }
    dt{
      padding-right: 40px;
      font-family: g.$tradeGothic;
      .ttl{
        font-weight: bold;
      }
    }
    dd{
      padding-right: 30px;
      font-size: 12px;
      span{
        display: inline-block;
      }
      .date{
        padding-right: 12px;
        font-family: g.$tradeGothic;
      }
    }
  }
  /* nav
  ---------------------------------------------------------------------------*/
  &__nav{
    padding: 25px 0 35px;
  }
  &__nav-item{
    display: table;
    margin-top: 15px;
    width: 100%;
    font-size: 12px;
    a{
      -webkit-backface-visibility:hidden;
      backface-visibility:hidden;
    }
    a:hover{
      border-bottom: solid 1px g.$color-black;
    }
    dt,dd{
      display: table-cell;
      vertical-align: top;
    }
    dt,.inner{
      width: 92px;
      font-weight: bold;
      font-family: g.$tradeGothic;
      font-size: 14px;
    }
    dd{
      width: calc(100% - 185px);
    }
    ul{
      letter-spacing: -0.4em;
    }
    li{
      display: inline-block;
      padding-right: 4px;
      letter-spacing: normal;
      &:after{
        content: "|";
        color: #ccc;
      }
      &:last-child:after{
        content: none;
      }
      a{
        padding-right: 4px;
      }
    }
  }
  /* single-nav
  ---------------------------------------------------------------------------*/
  &__single-nav{
    border-top: solid 1px #111;
    a{
      &:hover{
        text-decoration: underline;
      }
    }
    ul{
      padding: 34px 0;
      letter-spacing: -0.4em;
    }
    li{
      display: inline-block;
      letter-spacing: normal;
      padding-right: 18px;
      font-size: 12px;
      font-weight: bold;
      font-family: g.$tradeGothic;
      vertical-align: middle;
    }
  }
  /* sns
  ---------------------------------------------------------------------------*/
  &__sns{
    position: relative;
    text-align: right;
    top: 0;
    right: 0;
    a{
      i, img{
        transition: all 200ms g.$ease-base;
      }
      &:hover{
        img {
          opacity: .5;
        }
        i{
          color: g.$color-gray;
        }
      }
    }
    ul{
      @include g.flex-style01();
      position: absolute;
      top: -58px;
      right: 0;
      line-height: 1;
    }
    .dbp{
      padding-right: 15px;
      path{
       transition: all 200ms g.$ease-base;
      }
      svg{
        width: 151px;
        height: 22px;
        &:hover{
          path{
            fill: g.$color-gray;
          }
        }
      }
    }
    li{
      padding-left: 20px;
      a{
        display: block;
        i{
          font-size: 20px;
        }
        img{
        }
      }
    }
  }
  /* logo
  ---------------------------------------------------------------------------*/
  &__logo{
    padding: 20px 0;
    .img{
      padding-right: 20px;
      a{
        display: inline-block;
        width: 160px;
        height: 29px;
        background: url(/assets/images/common/logo.svg) no-repeat;
        background-size: 160px auto;
        text-indent: -9999px;
        white-space: nowrap;
      }
    }
    .txt{
      display: inline-block;
      font-size: 11px;
      font-weight: bold;
      font-family: g.$tradeGothic;
    }
    span{
      display: inline-block;
    }
  }
  /* bt
  ---------------------------------------------------------------------------*/
  &__bt{
    border-top: solid 1px #111;
    height: 109px;
    @include g.flex-style01();
  }
  /* address
  ---------------------------------------------------------------------------*/
  &__address{
    a{
      &:hover{
        text-decoration: underline;
      }
    }
    ul{
      @include g.inline-style();
    }
    li{
      padding-left: 0.5em;
      font-size: 11px;
      font-family: g.$tradeGothic;
      font-weight: bold;
      &:before{
        content: "|";
        padding-right: 0.5em;
      }
      &:first-child:before{
        content: none;
      }
      i{
        padding-left: 4px;
      }
    }
  }
  /* copy
  ---------------------------------------------------------------------------*/
  &__copy{
    display: block;
    font-size: 11px;
    text-align: right;
    font-family: g.$tradeGothic;
    font-weight: bold;
  }
}

@include g.media-sp {
  /*--------------------------------------------------------------------------
    global-footer
  ---------------------------------------------------------------------------*/
  .global-footer{
    padding: 30px 0 40px;

    &--s &__bt{
      padding-top: 0;
    }
    /* news
    ---------------------------------------------------------------------------*/
    &__news{
      a{
        padding: 15px 20px;
        &:before{
          content: none;
        }
        &:hover{
          color: g.$color-black;
          &:before{
            content: none;
          }
          svg.ico{
            path{
              fill: #888;
            }
            rect{
              stroke: #888;
            }
          }
        }
      }
      svg.ico{
        right: 20px;
        width: 20px;
        height: 20px;
        margin-top: -10px;
      }
      dl{
        height: auto;
      }
      dt{
        width: 100%;
        padding-right: 0;
        span{
          display: inline-block;
          vertical-align: top;
        }
        .ttl{
          padding-right: 11px;
        }
        .date{
          padding-top: 1px;
          font-size: 12px;
          color: g.$color-gray;
        }
      }
      dd{
        margin-top: 0;
        font-size: 12px;
        font-weight: normal;
        .date{
          display: none;
        }
      }
    }
    /* nav
    ---------------------------------------------------------------------------*/
    &__nav{
      display: none;
      padding: 0;
    }
    /* single-nav
    ---------------------------------------------------------------------------*/
    &__single-nav{
      display: none;
      border-top: none;
    }
    /* sns
    ---------------------------------------------------------------------------*/
    &__sns{
      margin-top: 40px;
      text-align: left;
      ul{
        position: static;
      }
      .dbp{
        padding: 0 20px 0 0;
      }
      li{
        display: inline-block;
        padding: 0;
        a{
          display: block;
          path{
            fill: #888;
          }
          i{
            font-size: 20px;
            color: #888;
          }
        }
      }
    }
    /* logo
    ---------------------------------------------------------------------------*/
    &__logo{
      padding: 0;
      margin-top: 34px;
      .img{
        padding-right: 20px;
        a{
          background-image: url(/assets/images/common/logo_gry.svg);
        }
      }
      .txt{
        margin-top: 5px;
        display: block;
        font-size: 12px;
      }
      img{
        width: 165px;
      }
    }
    /* bt
    ---------------------------------------------------------------------------*/
    &__bt{
      border-top: none;
      height: auto;
      align-items: center;
      color: #888;
      a{
        color: #888;
      }
    }
    /* address
    ---------------------------------------------------------------------------*/
    &__address{
      margin-top: 10px;
      font-weight: bold;
      line-height: 1.5;
      li{
        padding: 0 0.5em 0 0;
        font-size: 11px;
        &:first-child{
          width: 100%;
        }
        &:before{
          content: none;
        }
        // &:last-child:before{
        //   content: "|";
        //   font-weight: normal;
        // }
      }
    }
    /* copy
    ---------------------------------------------------------------------------*/
    &__copy{
      margin-top: 23px;
      display: block;
      font-size: 11px;
      font-weight: bold;
      text-align: left;
    }
    /* global-footer--s
    ---------------------------------------------------------------------------*/
    &--s &__logo{
      margin-top: 30px;
    }
    &--s &__bt{
      display: block;
    }
  }
}

/*--------------------------------------------------------------------------
  aside
---------------------------------------------------------------------------*/
aside.bt-cont{
  position: relative;
  z-index: 1;
  background: g.$color-white;
}

/*--------------------------------------------------------------------------
  breadcrumb
---------------------------------------------------------------------------*/
.breadcrumb{
  margin-top: 30px;
  padding: 30px 0;
  &__list{
    letter-spacing: -0.4em;
  }
  &__item{
    display: inline-block;
    position: relative;
    margin-right: 12px;
    letter-spacing: normal;
    font-size: 12px;
    font-family: g.$tradeGothic;
    & + &{
      &:before{
        content: "\f105";
        position: absolute;
        top: 0;
        left: 0;
        font-family: 'fontAwesome';
        color: g.$color-gray;
        line-height: 1.4;
      }
      a,
      .inner{
        display: inline-block;
        padding-left: 12px;
      }
    }
    &:last-child{
      &:before{
        color: #ccc;
      }
      .inner{
        color: g.$color-gray;
      }
    }
  }
}

@include g.media-sp {
  .breadcrumb{
    margin: 0 6px 0 0;
    padding: 30px 0;
    &__list{
      margin-right: -6px;
    }
    &__item{
     margin-right: 6px;
      &:not(:first-child) a,
      &:not(:first-child) .inner{
        padding-left: 9px;
      }
    }
  }
}

/* svg */
.ico_wht-c-1 {
  fill: none;
  stroke: #fff;
  stroke-width: 3px;
}
.ico_wht-c-2 {
  fill: #fff;
  fill-rule: evenodd;
}
.ico_blk-c-1 {
  fill: none;
  stroke: #111;
  stroke-width: 3px;
}
.ico_blk-c-2 {
  fill: #111;
  fill-rule: evenodd;
}
.ico_gry-1 {
  fill: none;
  stroke: #888889;
  stroke-width: 3px;
}
.ico_gry-2 {
  fill: #888;
  fill-rule: evenodd;
}
