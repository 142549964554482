@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3501574, 2017-12-22T02:35:39-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: TradeGothicLT-Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/trade-gothic/bold-63882/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted (c) 1989, 1992, 2003 Linotype Library GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype Library GmbH, and 
 * Licensed pageviews: 500,000
 * 
 * Webfont: TradeGothicLTPro by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/trade-gothic/pro-roman/
 * Copyright: Copyright &#x00A9; 2007 Linotype GmbH, www.linotype.com. All rights reserved. This font software may not be reproduced, modified, disclosed or transferred without the express written approval of Linotype GmbH. Trade Gothic is either a registered trademark
 * Licensed pageviews: 250,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3501574
 * 
 * © 2017 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/356e06");
@font-face {
  font-family: 'TradeGothicLT';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("webfonts/356E06_1_0.eot");
  src: url("webfonts/356E06_1_0.eot?#iefix") format("embedded-opentype"), url("webfonts/356E06_1_0.woff2") format("woff2"), url("webfonts/356E06_1_0.woff") format("woff"), url("webfonts/356E06_1_0.ttf") format("truetype"); }

@font-face {
  font-family: 'TradeGothicLT';
  font-weight: 700;
  font-display: swap;
  src: url("webfonts/356E06_0_0.eot");
  src: url("webfonts/356E06_0_0.eot?#iefix") format("embedded-opentype"), url("webfonts/356E06_0_0.woff2") format("woff2"), url("webfonts/356E06_0_0.woff") format("woff"), url("webfonts/356E06_0_0.ttf") format("truetype"); }
