@use "../import/common" as g;

/* *
 *
 *  detail.css
 *
 *
 * */

/*--------------------------------------------------------------------------
  page-capability-detail
---------------------------------------------------------------------------*/

.page-capability-detail{
  .sec{
    .lead{
      .ttl{
        font-size: 21px;
        font-weight: bold;
      }
      .txt{
        margin-top: 5px;
        line-height: 2;
      }
    }
  }
}

@include g.media-sp {
  .page-capability-detail{
    .sec{
      padding-bottom: 0;
      border-bottom: none;
      .lead{
        .ttl{
          font-size: 18px;
          line-height: 1.5;
        }
        .txt{
          margin-top: 8px;
          line-height: 1.79;
        }
      }
    }
  }
}
