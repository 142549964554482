@use "../import/common" as g;

/* *
 *
 *  index.css
 *
 *
 * */

/*--------------------------------------------------------------------------
  page-privacypolicy
---------------------------------------------------------------------------*/
.page-privacypolicy{
  .sec{
    margin-top: 55px;
    font-size: 14px;
    line-height: 2;
    h2{
      font-weight: bold;
    }
    .link-blank a{
      display: inline-block;
      padding-right: 15px;
      background: url(/assets/images/common/ico_blank_blk.svg) no-repeat 100% 50%;
      background-size: 10px auto;
    }
    .txt + .txt{
      margin-top: 30px;
    }
  }
}

@include g.media-sp {
  .page-privacypolicy{
    .sec{
      line-height: 1.78;
      .link-blank a{
        padding-right: 17px;
      }
      .txt + .txt{
        margin-top: 30px;
      }
    }
  }
}
