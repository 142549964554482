@use "../import/common" as g;
/* *
 *
 *  utility
 *  u-***,l-***
 *
 * */

/*--------------------------------------------------------------------------
  layout
---------------------------------------------------------------------------*/
/* l-inner-base
-------------------------------------------------------------------------------- */
.l-inner-base{
  width: g.$pc-cont-max-width;
  margin: 0 auto;
  &--sp{
    width: auto;
  }
}
.l-inner-base-s{
  width: g.$pc-cont-small-width;
  margin: 0 auto;
}

/* l-inner-space
-------------------------------------------------------------------------------- */
.l-inner-space{
  padding: 0 g.$pc-space;
}

/* l-cont-base
-------------------------------------------------------------------------------- */
.l-cont-base{
  margin-top: 40px;
}

@include g.media-pc-break {
  .l-inner-base{
    width: 100%;
    padding: 0 g.$pc-space;
  }
}

@include g.media-sp {
  .l-inner-space{
    padding: 0 g.$sp-space;
    padding: 0;
  }
  .l-inner-base{
    width: 100%;
    padding: 0 g.$sp-space;
    &--pc{
      padding: 0;
    }
  }
  .l-inner-base-s{
    width: 100%;
    padding: 0 g.$sp-space;
  }
}



/*--------------------------------------------------------------------------
  flex
---------------------------------------------------------------------------*/
//flex wrap
.l-c-wrap{
  display: flex;
  flex-wrap: wrap;
  &--lr{
    justify-content: space-between;
  }
}
.l-flex-col{
  display: flex;
}


/*--------------------------------------------------------------------------
  u
---------------------------------------------------------------------------*/
/* u-font-en
-------------------------------------------------------------------------------- */
.u-font-en{
  font-family: g.$tradeGothic;
}

/* u-list-style
-------------------------------------------------------------------------------- */
.u-list-style{
  padding-left: 1em;
  text-indent: -1em;
}

/* u-list-hyphen{
-------------------------------------------------------------------------------- */
.u-list-hyphen{
  padding-left: 0.7em;
  text-indent: -0.7em;
}

/* u-list-style
-------------------------------------------------------------------------------- */
.u-list-style{
  padding-left: 1em;
  text-indent: -1em;
}

/* u-sc-in
-------------------------------------------------------------------------------- */
.u-sc-in{
  transform: translateY(70px);
  opacity: 0;
  transition: opacity 300ms g.$ease-base ,transform 300ms g.$ease-base;
  &--fade{
    transform: translateY(0);
  }
  &.start{
    opacity: 1;
    transform: translateY(0);
  }
}

@include g.media-sp {
  .u-sc-in{
    transform: translateY(70px);
  }
}

/* u-first-racket
-------------------------------------------------------------------------------- */
.u-first-bracket,
br:not(.u-for-sp) + .u-bracket {
  margin-left: -0.6em;
}

/* u-hover
-------------------------------------------------------------------------------- */
.u-hover a,
a.u-hover{
  text-decoration: underline;
  &:hover{
    text-decoration: none;
  }
}

/* u-hover
-------------------------------------------------------------------------------- */
.u-hover-2 a,
a.u-hover-2{
    text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

/* dis
---------------------------------------------------------------------------*/
.u-for-pc{
  display: block;
}
.u-for-sp{
  display: none;
}

@include g.media-sp {
  .u-for-pc{
    display: none;
  }
  .u-for-sp{
    display: block;
  }
}
