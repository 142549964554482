@use "../import/common" as g;

/* *
 *
 *  access.css
 *
 *
 * */

/*--------------------------------------------------------------------------
  sec
---------------------------------------------------------------------------*/
.sec-map{
  .address{
    font-size: 17px;
    font-weight: bold;
  }
  #googleMap{
    margin-top: 40px;
    width: 100%;
    height: 576px;
  }
  .access{
    margin-top: 20px;
  }
  .link-blank{
    margin-top: 30px;
    vertical-align: middle;
    a{
      padding-right: 14px;
      background: url(/assets/images/common/ico_blank.svg) no-repeat 100% 50%;
      background-size: 10px auto;
      color: g.$color-gray;
      font-size: 12px;
    }
  }
}
.sec-photo{
  margin-top: 75px;
  .photo-box{
    margin: 0 -6px;
    &__row{
      display: table;
      width: 100%;
      vertical-align: top;
    }
    &__col{
      display: table-cell;
        width: calc(100% - 67%);
        vertical-align: top;
        padding: 6px;
      &--wide{
        width: 67%;
      }
      img{
        width: 100%;
        height: auto;
      }
      p + p{
        padding-top: 12px;
      }
    }
  }
}

@include g.media-sp {
  .sec-map{
    .address{
      font-size: 14px;
      line-height: 1.5;
    }
    #googleMap{
      margin-top: 13px;
      height: 420px;
    }
    .access{
      margin-top: 18px;
      font-size: 12px;
    }
    .link-blank{
      margin-top: 7px;
      a{
        text-decoration: underline;
      }
    }
  }
  .sec-photo{
    margin-top: 46px;
    .photo-box{
      margin: 0;
      &__col{
        padding: 6px 0 0;
        display: block;
        width: auto;
        p+p{
          padding: 0;
        }
        &:not(.photo-box__col--wide){
          display: flex;
          margin: 0 -3px;
          p{
            padding: 0 3px;
            width: 50%;
          }
        }
      }
      &__row{
        display: block;
        &--bt{
          @include g.flex-style01();
          .photo-box__col--wide{
            order: 1;
          }
          .photo-box__col:first-child{
            order: 2;
          }
        }
      }
    }
  }
}
