@use "../import/common" as g;

/* *
 *
 *  form
 *  wordpress form common
 *
 * */

/*--------------------------------------------------------------------------
  p-form
---------------------------------------------------------------------------*/

.p-form{
  padding-bottom: 100px;
  margin-top: 40px;

  /* contact form 7
  ---------------------------------------------------------------------------*/
  .screen-reader-response,
  .wpcf7-response-output.wpcf7-mail-sent-ok{
    display: none!important;
  }
  .wpcf7-form-control-wrap.privacy{
    display: flex;
    flex-flow: column nowrap;
    .wpcf7-not-valid-tip.error{
      order: 1;
    }
    .wpcf7-form-control.wpcf7-checkbox{
      order: 2;
      input[type='checkbox']{
        margin: 3px;
      }
    }
  }
  .ajax-loader{
    position: fixed;
    visibility: hidden;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(245,245,245,0.9);
    z-index: 2;
    transition: all 300ms cubic-bezier(0.47, 0, 0.745, 0.715);
    &.is-active{
      opacity: 1;
      visibility: visible;
    }
    &:before{
      content: 'NOW SENDING';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200px;
      height: 100px;
      padding-top: 70px;
      background: url(/assets/images/common/ico_form_loader.gif) no-repeat 50% 0;
      background-size: 60px auto;
      margin: -50px 0 0 -100px;
      text-align: center;
      font-family: g.$tradeGothic;
    }
  }

  /* error
  ---------------------------------------------------------------------------*/
  .error{
    display: block;
    color: g.$color-red!important;
    font-size:  14px!important;
    font-weight: bold;
  }
  /* &__lead
  ---------------------------------------------------------------------------*/
  &__lead{
    padding-bottom: 14px;
    line-height: 2;
    font-size: 14px;
    .error-txt{
      display: none;
      span{
        display: block;
      }
      .caution{
        color: g.$color-red;
        font-weight: bold;
      }
      .sub{
        margin-top: 10px;
      }
    }
    .notes{
      margin-top: 50px;
      font-size: 12px;
      .req{
        color: g.$color-red;
      }
    }
    /* 完了ページボタン */
    .btn-comp {
      margin-top: 50px;
    }
  }
  /* エラー表示 テキスト出し */
  .page-form-error &{
    .error-txt{
      display: block;
      margin-top: 70px;
    }
  }
  /* &__cont
  ---------------------------------------------------------------------------*/
  &__cont{
    border-top: solid 1px #ccc;
    padding-top: 40px;
  }
  /* &__submit
  ---------------------------------------------------------------------------*/
  &__submit{
    margin-top: 16px;
    font-size: 14px;
    .privacypolicy{
      border-radius: 4px;
      overflow-y: scroll;
      height: 400px;
      padding: 40px;
      border: solid 1px #ccc;
      font-size: 12px;
      .ttl{
        margin-top: 1em;
        font-weight: bold;
        &:first-child{
          margin-top: 0;
        }
      }
      ul{
        margin-top: 1em;
      }
    }
    .agree-check{
      margin-top: 20px;
      .error{
        margin-bottom: 5px;
      }
    }
    .btn-wrap{
      position: relative;
      margin-top: 20px;
      input[type="checkbox"]{
        font-size: 20px;
      }
    }
  }
}
/* compalete
  ---------------------------------------------------------------------------*/
.page-form-comp{
  .p-form{
    padding-bottom: 80px;
  }
  ///入力form非表示
  .p-form__cont-input{
    display: none;
  }
  .p-form__cont-comp{
    display: block;
  }
  .p-form__lead{
    padding-bottom: 0;
    .txt{
      font-size: 14px;////???
    }
  }
  .p-bt-cv{
    padding-top: 10px;
    padding-bottom: 37px;
  }
  .p-sns {
    margin-top: 0;
  }
  .p-sns + .p-bt-cv{
    padding-top: 40px;
  }
}

@include g.media-sp {
  .p-form{
    margin-top: 47px;
    padding-bottom: 10px;
    /* error
    ---------------------------------------------------------------------------*/
    .error{
      font-weight: normal;
    }
    /* &__lead
    ---------------------------------------------------------------------------*/
    &__lead{
      padding-bottom: 16px;
      line-height: 2;
      .error-txt{
        .caution{
          line-height: 1.5;
        }
        .sub{
          margin-top: 10px;
        }
      }
      .notes{
        margin-top: 26px;
      }
      .btn-comp{
        margin-top: 20px;
      }
    }
  /* エラー表示 テキスト出し */
  .page-form-error &{
    .error-txt{
      margin-top: 47px;
    }
  }
    /* &__cont
    ---------------------------------------------------------------------------*/
    &__cont{
      padding-top: 20px;
    }
    /* &__submit
    ---------------------------------------------------------------------------*/
    &__submit{
      margin-top: 20px;
      .privacypolicy{
        padding: 15px 20px;
        height: 420px;
        line-height: 1.5;
        .ttl{
          margin-top: 1.5em;
        }
        ul{
          margin-top: 0;
        }
      }
      .agree-check{
        .mwform-checkbox-field{
          display: block;
          border-radius: 5px;
          background: #FFF;
          padding: 12px 20px;
        }
        .error{
          margin-bottom: 15px;
          font-weight: normal;
          line-height: 1.5;
        }
      }
    }
  }
  /* compalete
  ---------------------------------------------------------------------------*/
  .page-form-comp{
    .p-form{
      padding-bottom: 8px;
    }
    .p-bt-cv{
      padding-top: 32px;
      padding-bottom: 8px;
    }
    .p-sns{
      margin-top: 40px;
    }
  }
}


/*--------------------------------------------------------------------------
  .sec-download
---------------------------------------------------------------------------*/
.sec-download{
  .lead{
    margin-top: 40px;
    padding-bottom: 10px;
   .txt{
      line-height: 2;
    }
    .caution{
      margin-top: 50px;
      .error-txt .sub{
        margin-bottom: 40px;
      }
    }
  }
}

@include g.media-sp {
  .sec-download{
    .lead{
      margin-top: 55px;
      padding-bottom: 15px;
      .caution{
        margin-top: 38px;
        .error-txt .sub{
          margin-bottom: 30px;
        }
      }
    }
  }
}

/*--------------------------------------------------------------------------
  .page-careers
---------------------------------------------------------------------------*/
.page-careers{
  .sec-info{
    .lead p{
      margin-top: 40px;
    }
    .overview{
      margin-top: 80px;
    }
  }
  .sec-instagram{
    padding-top: 100px;
    .ttl{
      padding-left: 45px;
      background: url(/assets/images/common/ico_instagram.png) no-repeat 0 50%;
      background-size: 32px auto;
      font-size: 14px;
      font-weight: bold;
      font-family: g.$tradeGothic;
      line-height: 32px;
      a{
        display: inline-block;
        padding: 2px 15px 0 0;
        background: url(/assets/images/common/ico_blank.svg) no-repeat 100% 44%;
        background-size: 10px auto;
        color: g.$color-gray;
        &:hover{
          color: g.$color-black;
          background-image: url(/assets/images/common/ico_blank_blk.svg);
          background-size: 10px auto;
        }
      }
    }
    .cont{
      margin-top: 30px;
      .imgs{
        ul{
          display: flex;
        }
        li{
          width: 20%;
          a{
            overflow: hidden;
            position: relative;
            display: block;
            &:before{
              display: block;
              content: "";
              padding-top: 100%;
            }
          }
          img{
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%,-50%);
            height: auto;
          }
        }
      }
    }
  }
  .sec-form{
    margin-top: 100px;
  }
  /* error */
  &.page-form-error {
    .l-inner-base{
      width: g.$pc-cont-small-width;
    }
    .sec-info,
    .sec-instagram{
      display: none;
      margin: 0;
      overflow: hidden;
    }
    .sec-form{
      margin-top: 0;
      .c-label-page{
        margin-top: 0;
        display: none;
      }
    }
  }
  &.page-form-comp &{
    .sec-form{
      margin-top: 0;
    }
  }
}


@include g.media-sp {
  .page-careers{
    .sec-info{
      padding-bottom: 25px;
      .lead p{
        margin-top: 25px;
      }
      .overview{
        margin-top: 33px;
        .c-table-style{
         border-bottom: none;
        }
      }
    }
    .sec-instagram{
      background: #FFF;
      padding: 30px g.$sp-space;
      .ttl{
        font-size: 13px;
      }
      .cont{
        .imgs{
          ul{
            display: flex;
            flex-wrap: wrap;
          }
          li{
            float: left;
            width: 50%;
            &:first-child{
              width: 100%;
            }
          }
        }
      }
    }
    .sec-form{
      margin-top: 40px;
      .c-label-page{
        margin-top: 0;
      }
    }
    .p-form{
      margin-top: 0;
    }
    .page-form-error &{
      .l-inner-base{
        width: 100%;
      }
      .sec-form{
        margin-top: 0;
        .c-label-page{
          margin-top: 0;
          display: none;
        }
      }
    }
  }
  .page-careers-comp .p-sns{
    margin-top: 25px;
  }
}
