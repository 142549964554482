@use "../import/common" as g;

/* *
 *
 *  project
 *  p-***
 *
 * */

/*--------------------------------------------------------------------------
  p-case-list-index
---------------------------------------------------------------------------*/
$duration: 600ms;
@include g.media-pc {
  .p-case-list-index{
    position: relative;
    overflow: hidden;
    &.is-hover .areas .item a:before{
      height: 100%;
    }
    .areas .item{
      a:before{
        @include g.pseudo($width:0);
        top: 0;
        left: 0;
        width: 0;
         border-left: solid 1px rgba(245,245,245,0.3);
      }
      &:first-child a:before{
        content: none;
      }
    }
    .areas{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      .item{
        flex: 1;
        position: relative;
        z-index: 1;
        &:before{
          content: "";
          float: left;
          padding-top: 168.7%;
        }
        a{
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          align-items: center;
        }
        &.is-hover .txt-wrap{
          // transform: translateY(-31%);
          .lead{
            opacity: 1;
            height: auto;
          }
          .more{
            margin-top: 46px;
            width: auto;
            height: auto;
            min-height: 26px;
            padding-right: 38px;
            background: url(/assets/images/common/arw_wht.svg) no-repeat 100% 50%;
            background-size: 26px 26px;
            span{
              opacity: 1;
              width: auto;
              height: inherit;
            }
          }
        }
        &.item--black.is-hover .txt-wrap{
          color: g.$color-black;
          .more{
            background-image: url(/assets/images/common/arw_blk.svg);
          }
          .ttl{
            &::before{
              border-color: g.$color-black;
            }
          }
        }
        .txt-wrap{
          position: relative;
          margin: 0 auto;
          width: 100%;
          z-index: 2;
          color: #FFF;
          padding: 0 12.5%;
          .ttl{
            position: relative;
            padding-top: 40px;
            font-size: 13px;
            font-weight: normal;
            line-height: 1.2;
            letter-spacing: 0.1em;
            font-family: g.$tradeGothic;
            &:before{
              @include g.pseudo(32px,auto);
              top: 0;
              border-top: solid 2px #FFF;
            }
            .em{
              display: block;
              font-size: 25px;
            }
          }
          .lead{
            overflow: hidden;
            font-size: 24px;
            font-weight: bold;
            opacity: 0;
            width: 100%;
            height: 0;
            line-height: 1.5;
            span{
              display: block;
              width: 100%;
              padding-top: 25px;
            }
          }
          .more{
            display: inline-block;
            margin-top: 29px;
            width: 43px;
            height: 43px;
            background: url(/assets/images/common/ico_plus.svg) no-repeat 0 0;
            background-size: 40px 40px;
            font-weight: bold;
            font-size: 14px;
            span{
              // transition: all 300ms g.$ease-base;
              display: inline-block;
              width: 0;
              height: 0;
              opacity: 0;
              overflow: hidden;
              vertical-align: middle;
            }
          }
        }
        .img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          img{
            width: 100vw;
            max-width: 100vw;
            height: auto;
          }
        }
      }
    }
    &.active .imgs li{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .imgs{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      letter-spacing: -0.4em;
      white-space: nowrap;
      img{
        width: 100vw;
        height: auto;
      }
      li{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: transform 10000ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms;
        &.is-hover{
          // transition-delay: 0s;
          transition: transform 18000ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
          transform: scale(1.1);
        }
        span{
          display: block;
        }
      }
    }
  }
}

@include g.media-sp {
  .p-case-list-index{
    .item{
      a{
        position: relative;
        display: block;
        color: #FFF;
      }
      &.disp .txt-wrap{
        .lead{
          opacity: 0!important;
        }
        .more{
          span{
            display: none;
            opacity: 0!important;
            width: auto;
          }
        }
      }
      .txt-wrap{
        opacity: 1!important;
        height: auto!important;
      }
      &.item--black .txt-wrap{
        color: g.$color-black;
        .more{
          background-image: url(/assets/images/common/arw_blk.svg);
        }
      }
      .ttl{
        position: absolute;
        top: 50%;
        left: g.$sp-space;
        font-size: 13px;
        transform: translateY(-50%);
        font-weight: normal;
        letter-spacing: 0.07em;
        font-family: g.$tradeGothic;
        span{
          display: block;
        }
        .em{
          font-size: 20px;
          line-height: 1.2;
        }
      }
      .lead{
        display: none;
      }
      .more{
        position: absolute;
        top: 50%;
        right: g.$sp-space;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        background: url(/assets/images/common/arw_wht.svg) no-repeat 0 0;
        background-size: 20px 20px;
        span{
          display: none;
        }
      }
      .img{
        display: block;
        opacity: 1!important;
        img{
          width: 100vw;
          height: auto;
          transform: none!important;
        }
      }
    }
    .imgs{
      display: none;
    }
  }
}


/*--------------------------------------------------------------------------
  p-bt-cv
---------------------------------------------------------------------------*/
.p-bt-cv{
  padding-top: 90px;
  position: relative;
  background: g.$color-white;
  z-index: 1;
  .p-sns + &{
    padding-top: 40px;
  }
  &--article{
    padding-top: 60px;
  }
  ul{
    @include g.flex-style01;
  }
  li{
    width: calc(50% - 15px);
    a,
    &:before{
      width: 100%;
    }
    a{
      @include g.flex-style01;
      padding: 0 40px;
      min-height: 121px;
      text-align: left;
      > span{
        display: block;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        span{
          display: block;
          text-align: left;
        }
      }
    }
    .ttl{
      font-weight: bold;
      font-size: 19px;
    }
    .txt{
      font-size: 13px;
      font-weight: normal;
    }
  }
}

@include g.media-sp {
  .p-bt-cv{
    padding-top: 40px;
    .p-sns + &{
      padding-top: 40px;
    }
    li{
      width: 100%;
      & + li{
        margin-top: 15px;
      }
      a,
      &:before{
        width: 100%;
      }
      a{
        padding: 0 20px;
        height: auto;
        min-height: 60px;
        > span{
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .ttl{
        font-size: 14px;
      }
      .txt{
        display: none;
      }
    }
  }
}


/*--------------------------------------------------------------------------
  p-sns
---------------------------------------------------------------------------*/
.p-sns{
  margin-top: 105px;
  &__inner{
    border: solid 4px #090204;
    padding: 35px 35px 32px;
    @include g.flex-style01;
  }
  &__ttl{
    margin-top: 0;
    .c-label-page__inner:before{
      margin-top: -3px;
    }
  }
  &__cont{
    margin-top: 6px;
  }
  &__txt{
    margin-top: 9px;
    font-size: 14px;
  }
  &__list{
    margin: 0 -16px;
    padding-right: 45px;
    li{
      display: inline-block;
      margin: 0 16px;
      i, img{
        transition: all g.$duration-base g.$ease-base;
        font-size: 26px;
      }
      a:hover img{
        opacity: .5;
      }
      a:hover i{
        color: g.$color-gray;
      }
    }
  }
}

@include g.media-sp {
  .p-sns{
    margin-top: 40px;
    &__inner{
      padding: 30px;
    }
    &__cont{
      margin-top: 20px;
    }
    &__txt-wrap{
      width: 100%;
      text-align: center;
    }
    &__ttl{
      margin-top: 0;
      span{
        font-size: 19px;
        padding: 0;
        &:before{
          content: none;
        }
      }
    }
    &__txt{
      margin-top: 12px;
      font-size: 13px;
      line-height: 2;
    }
    &__list{
      margin: 28px 0 0;
      padding-right: 0;
      width: 100%;
      text-align: center;
    }
  }
}


/*--------------------------------------------------------------------------
  p-contact
---------------------------------------------------------------------------*/
.p-contact{
  position: relative;
  padding: 120px 0;
  background: g.$color-black url('/assets/images/common/contact_bg_pc.jpg') no-repeat 50% 50%;
  background-position: 50% 50%;
  background-size: cover;
  line-height: 2;
  z-index: 1;
  &__inner{
    width: 640px;
    margin: 0 auto;
    padding-top: 116px;
    background: url('/assets/images/common/contact_bg_obj.svg') no-repeat 50% 0;
    background-size: 100% auto;
    background-size: 640px;
  }
  &__ttl{
    margin-top: 8px;
    color: g.$color-red;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.5;
  }
  &__lead{
    margin-top: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    line-height: 2;
  }
  &__btn{
    margin: 30px -9px 0;
    letter-spacing: -0.4em;
    li{
      display: inline-block;
      letter-spacing: normal;
      margin: 0 9px;
      text-align: center;
      &:before{
        background: rgba(255,255,255,0.1);
      }
      a{
        padding: 22px 20px;
        &:before{
          background: g.$color-red;
        }
        & > span{
          padding: 0 35px;
          background-size: 30px 30px;
          line-height: 30px;
        }
      }
      &:before,
      a{
        width: 310px;
      }
    }
  }
}

@include g.media-sp {
  .p-contact{
    padding: 60px 0;
    background-image: url('/assets/images/common/contact_bg_sp.jpg');
    &__inner{
      width: 100%;
      margin: 0 auto;
      padding: 15% g.$sp-space 0;
      background: url('/assets/images/common/contact_bg_obj.svg') no-repeat 50% 0;
      $spLR : g.$sp-space * 2;
      background-size: (100% - $spLR - 0.5%) auto;
    }
    &__ttl{
      font-size: 18px;
      line-height: 1.5;
    }
    &__lead{
      margin-top: 11px;
      font-weight: normal;
    }
    &__btn{
      margin: 10px 0 0;
      li{
        display: block;
        margin: 15px 0 0;
        &:before,
        a{
          width: 100%;
        }
        a{
          padding: 20px;
          > span{
            display: block;
            padding: 0 25px;
            background-size: 20px 20px;
            line-height: 20px;
          }
        }
      }
    }
  }
}


/*--------------------------------------------------------------------------
  p-consultant-service-banner
---------------------------------------------------------------------------*/
.p-consultant-service-banner {
  position: fixed;
  z-index: 1000;
  display: none;
  
  .close {
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    img {
      width: 100%;
    }
  }
  a {
    display: block;
  }
  @include g.media-pc {
    bottom: 20px;
    right: 42px;
    .close {
      width: 30px;
      position: absolute;
      right: -31px;
      top: 9px;
      &:hover {
        opacity: .8;
      }
    }
    a {
      &::after {
        content: '';
        display: block;
        background: #fff;
        width: 537px;
        height: 200px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      img {
        width: 547px;
        position: relative;
        z-index: 10;
        transition: all g.$duration-base g.$ease-base;
      }
      &:hover {
        img {
          opacity: .8;
        }
      }
    }
  }
  @include g.media-sp {
    bottom: 20px;
    left: 2.7vw;
    .close {
      width: 14px;
      position: absolute;
      left: 0;
      top: -20px;
    }
    a {
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.30);
      img {
        width: 94.6vw;
      }
    }
  }
}