@use "../import/common" as g;
/* *
 *
 *  component
 *  c-***
 *
 * */

/*--------------------------------------------------------------------------
  c-btn-style
---------------------------------------------------------------------------*/
.c-btn-style{
  @include g.btn-style();
}
.c-btn-style--clr01{
  @include g.btn-style($isWhite: true);
}

@include g.media-sp {
  .c-btn-style{
    @include g.btn-style($width:100%,$padding: 17px 20px,$icoSize: 20px,$space: 30px);
    &:before{
      top: 8px;
      left: 8px;
    }
  }
  .c-btn-style--clr01{
    @include g.btn-style($width:100%,$padding: 17px 20px,$icoSize: 20px,$space: 30px,$isWhite: true);
    &:before{
      top: 8px;
      left: 8px;
    }
  }
}


@include g.media-sp {
  .c-btn-style a:hover{
    color: #FFF;
    &:before{
      content: none;
    }
    svg.ico{
      path{
        fill: #FFF;
      }
      rect{
        stroke: #FFF;
      }
    }
  }
  .c-btn-style--clr01 a:hover{
    color: g.$color-black;
    &:before{
      content: none;
    }
    svg.ico{
      path{
        fill: #111;
      }
      rect{
        stroke: #111;
      }
    }
  }
}


/*--------------------------------------------------------------------------
  c-ttl-style01
---------------------------------------------------------------------------*/
.c-ttl-style01{
  font-size: g.$font-size-ttl;
  font-family: g.$tradeGothic;
  font-weight: normal;
}
@include g.media-sp {
  .c-ttl-style01{
    font-size: g.$font-size-ttl-sp;
  }
}


/*--------------------------------------------------------------------------
  c-input
---------------------------------------------------------------------------*/
.c-input{
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: solid 1px #ccc;
  padding: 10px 15px;
  width: 100%;
  font-size: 14px;
  background: #FFF;
  &:-webkit-autofill {
    background: #FFF;
  }
  &:focus{
    outline: none;
  }
  &.error-style{
    border-color: g.$color-red;
    background-color: #fff2f2;
    &::-webkit-input-placeholder{
      color: g.$color-red;
    }
    &:-ms-input-placeholder{
      color: g.$color-red;
    }
    &:placeholder-shown {
      color: g.$color-red;
    }
    &:-moz-placeholder {
      color: g.$color-red;
    }
    font-weight: bold;
  }
}
.c-input-select-wrap{
  position: relative;
  appearance: none;
  border-radius: 4px;
  border: solid 1px #ccc;
  width: 100%;
  background: #FFF;
  .c-select{
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    width: 100%;
    padding: 9px 15px;
    border: none;
    z-index: 1;
    background: none;
    font-size: 14px;
    &::-ms-expand {
        display: none;
    }
  }
  &:before{
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    width: 40px;
    margin-top: -10px;
    content: "\f107";
    font-family: 'FontAwesome';
    font-size: 20px;
    line-height: 1;
    background: #FFF;
    text-align: right;
  }
}
.c-button-style{
  display: inline-block;
  position: relative;
  background: g.$color-black;
  min-width: 293px;
  &:before{
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.1);
  }
  .inner{
    display: block;
    position: relative;
    &:before{
      transform-origin: right;
      transition: g.$transitionHover;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #FFF;
    }
  }
  button,
  .input-btn{
    transition: g.$transitionHover;
    box-shadow: none;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    width: 100%;
    border-radius: 0;
    padding: 20px 68px 20px 32px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    z-index: 1;
    font-family: g.$YuGothic;
  }
  svg.ico{
    position: absolute;
    top: 50%;
    right: 32px;
    margin-top: -13px;
    width: 26px;
    height: 26px;
  }
  //btn color
  &:hover,
  &:active{
    button,
    .input-btn{
      color: g.$color-black;
    }
    .inner:before{
      width: 100%;
    }
    svg.ico{
      path{
        transition-delay: 150ms;
        fill: g.$color-black;
      }
      rect{
        transition-delay: 150ms;
        stroke: g.$color-black;
      }
    }
  }
}

@include g.media-sp {
  .c-input{
    min-height: 40px;
    padding: 8px 15px;
    &.error-style{
      font-weight: normal;
    }
  }
  textarea.c-input{
    height: 310px;
  }
  .c-input-select-wrap{
    .c-select{
      padding: 7px 15px;
      min-height: 38px;
      border: none;
      z-index: 1;
      background: none;
      font-size: 14px;
    }
    &:before{
      content: "";
      right: 22px;
      width: 11px;
      height: 6px;
      margin-top: -3px;
      background: url(/assets/images/common/arw_select_gry.svg) no-repeat 0 0;
      background-size: 11px 6px;
    }
  }
  .c-button-style{
    min-width: 100%;
    &:before{
      top: 8px;
      left: 8px;
    }
    &:after{
      right: 20px;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      background-size: 20px auto;
    }
    button{
      padding: 20px 47px 20px 20px;
      font-weight: normal;
    }

    //btn color
    &:hover,
    &:active{
      button,
      .input-btn{
        color: g.$color-white;
      }
      .inner:before{
        content: none;
      }
      svg.ico{
        path{
          transition-delay: none;
          fill: g.$color-white;
        }
        rect{
          transition-delay: none;
          stroke: g.$color-white;
        }
      }
    }
  }
}


/*--------------------------------------------------------------------------
  c-input
---------------------------------------------------------------------------*/
.c-input-item{
  display: table;
  width: 100%;
  & + &{
    margin-top: 16px;
  }
  dt,dd{
    display: table-cell;
    vertical-align: top;
    font-size: 14px;
  }
  dt{
    width: 185px;
    padding-right: 15px;
    font-weight: bold;
    .req{
      color: g.$color-red;
      font-size: 16px;
    }
  }
  dd{

  }
}

@include g.media-sp {
  .c-input-item{
    display: block;
    line-height: 1;
    & + &{
      margin-top: 10px;
    }
    dt,dd{
      display: block;
      width: 100%;
    }
    dt{
      padding: 0;
      font-weight: normal;
      .req{
        padding-left: 5px;
        font-size: 14px;
      }
    }
    dd{
      margin-top: 6px;
    }
  }
}


/*--------------------------------------------------------------------------
  c-pagination
---------------------------------------------------------------------------*/
.c-pagination{
  margin-top: 80px;
  ul{
    @include g.inline-style();
    text-align: center;
  }
  li{
    font-size: 14px;
    vertical-align: middle;
    &:not(.current) a{
      color: g.$color-gray;
    }
    &:not(.prev),
    &:not(.next){
      a{
        padding: 0 9px;
      }
    }
  }
  .prev,
  .next{
    margin: 0 17px;
    a,
    .inner{
      display: block;
      width: 30px;
      height: 30px;
      background-size: 30px auto;
      background-repeat: no-repeat;
    }
    a{
      background-image: url(/assets/images/common/arw_blk.svg);
      span{
        display: block;
        overflow: hidden;
        text-indent: -9999px;
        white-space: nowrap;
      }
    }
    .inner{
      background-image: url(/assets/images/common/arw_gry.svg);
    }
  }
  .prev{
    a,
    .inner{
      transform: rotate(180deg);
    }
  }
  .txt{
    display: none;
  }
}

@include g.media-sp {
  .c-pagination{
    position: relative;
    margin-top: 40px;
    ul{
      @include g.flex-style01();
      position: relative;
      z-index: 1;
    }
    li{
      &:not(.prev):not(.next):not(.txt){
        display: none;
      }
    }
    .prev,
    .next{
      margin: 0;
    }
    .txt{
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
}


/*--------------------------------------------------------------------------
  c-table-style
---------------------------------------------------------------------------*/
.c-table-style{
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: top;
    width: 100%;
    dt,dd{
      border-top: solid 1px #ccc;
      padding: 20px 0;
      font-size: 14px;
    }
    &:first-child{
      dt,dd{
        border-top: none;
      }
    }
    dt{
      width: 192px;
      margin-right: 32px;
      font-weight: bold;
    }
    dd{
      width: calc(100% - 224px);
      ul{
        line-height: 2;
      }
      .inner{
        display: inline-block;
        margin-right: 30px;
        vertical-align: middle;
      }
      .blank{
        vertical-align: middle;
        a{
          padding-right: 14px;
          background: url(/assets/images/common/ico_blank.svg) no-repeat 100% 50%;
          background-size: 10px auto;
          color: g.$color-gray;
          font-size: 12px;
        }
      }
    }
  }
}

@include g.media-sp {
  .c-table-style{
    border-top: none;
    dl{
      padding: 15px 0;
      border-top: solid 1px #ccc;
      dt,dd{
        width: 100%;
        border: none;
        padding: 0;
        line-height: 1.5;
      }
      dt{
        margin-right: 0;
      }
      dd{
        margin-top: 2px;
        ul{
          line-height: 1.5;
        }
        .inner{
          display: block;
          margin: 0;
        }
        .blank{
          a{
            text-decoration: underline;
          }
        }
      }
    }
  }
}


/*--------------------------------------------------------------------------
  c-label-page
---------------------------------------------------------------------------*/
.c-label-page{
  margin-top: 75px;
  font-weight: bold;
  font-family: g.$tradeGothic;
  line-height: 1;
  &__inner{
    position: relative;
    display: inline-block;
    padding-right: 43px;
    font-size: 19px;
    &:before{
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 32px;
      height: 0;
      margin-top: -5px;
      border-top: solid 2px g.$color-black;
    }
  }
}

@include g.media-sp {
  .c-label-page{
    margin-top: 60px;
    &__inner{
      padding-right: 38px;
      font-size: 26px;
      &:before{
        width: 24px;
      }
    }
    &__jp{
      display: block;
      font-size: 14px;
    }
  }
}

/*--------------------------------------------------------------------------
  c-ttl-page
---------------------------------------------------------------------------*/
.c-ttl-page{
  margin-top: 15px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.5;
}
@include g.media-sp {
  .c-ttl-page{
    margin-top: 10px;
    margin-top: 0;
    font-size: 14px;
  }
}

/*--------------------------------------------------------------------------
  c-case-list
---------------------------------------------------------------------------*/
.c-case-list{
  @include g.flex-style01();
  &__item{
    width: 50%;
    a{
      transition: all 600ms g.$ease-base;
      display: block;
      position: relative;
      color: #FFF;
      //margin-top: 2%;
      &:before{
        transition: all 300ms g.$ease-base;
        @include g.pseudo();
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.4);
        opacity: 0;
        z-index: 1;
      }
    }
    &--shadow{
      a:after{
        @include g.pseudo();
        top: 0;
        left: 0;
        box-shadow: inset 0px -187px 100px -100px rgba(0, 0, 0, 0.25);
      }
    }
    a:hover{
      .txt-wrap{
        // animation-timing-function: g.$ease-base;
        // animation-duration: 800ms;
        // animation-name: caseItemHover;
      }
      &:before{
        opacity: 1;
      }
      // .more{
      //   transition-delay: 50ms;
      //   height: auto;
      //   max-height: 50em;
      //   span{
      //     transition-delay: 50ms;
      //     opacity: 1;
      //   }
      // }
      // .img img{
      //   transform: scale(1.1);
      // }
    }
    .txt-wrap{
      transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
      position: absolute;
      bottom: 5%;
      left: 0;
      padding: 0 5.9%;
      width: 100%;
      height: auto;
      z-index: 1;
    }
    .label{
      font-size: 13px;
      font-family: g.$tradeGothic;
      font-weight: normal;
    }
    .catch{
      margin-top: 15px;
      font-size: 21px;
      font-weight: bold;
      line-height: 1.5;
    }
    .img{
      display: block;
      overflow: hidden;
      img{
        // transition: all 10000ms cubic-bezier(0.165, 0.84, 0.44, 1);
        width: 100%;
        height: auto;
      }
    }
  }
}

@keyframes caseItemHover {
  0%{
    opacity: 1;
   transform: translateY(-50%);
  }
  5%{
    opacity: 0;
   transform: translateY(-50%);
  }
  100%{
    opacity: 1;
   transform: translateY(-50%);
  }
}

@include g.media-sp {
  .c-case-list{
    &__item{
      width: 100%;
      a{
        &:before{
          content: none;
        }
      }
      .txt-wrap{
        padding: 0 g.$sp-space;
        // bottom: 50%;
      }
      .label{
        font-size: 13px;
        line-height: 1.4;
        .inner{
          display: block;
          font-size: 25px;
        }
      }
      .catch{
        display: none;
        margin: 0;
      }
    }
  }
}

/*--------------------------------------------------------------------------
  c-blog-list
---------------------------------------------------------------------------*/

.c-blog-list{
  ul{
    display: flex;
    flex-wrap: wrap;
  }
  &__item{
    margin-top: 85px;
    width: 50%;
    a{
      display: block;
    }
  }
  &__item-img{
    transition: all 600ms g.$ease-base;
    overflow: hidden;
    display: block;
    position: relative;
    .thumb-svg{
      transition: all 600ms g.$ease-base;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      image{
        display: block;
        width: 100%;
        height: auto;
        vertical-align: top;
        filter: url(#grayscale);
      }
    }
    .frame {
      display: block;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
  a:hover{
   .c-blog-list__item-img .thumb-svg{
      opacity: 0;
    }
  }
  &__item-img-inner{
    display: block;
    background-repeat: no-repeat;
    position: relative;
  }
  &__item-txt{
    margin-top: 15px;
    display: block;
    font-family: g.$tradeGothic;
    > span{
      display: block;
    }
    .info{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: g.$color-gray;
      font-size: 12px;
      span{
        display: block;
      }
    }
    .img{
      width: 36px;
      margin-right: 12px;
    }
    .txt-wrap{
      line-height: 1.4;
    }
    .date{
      color: g.$color-gray;
      font-size: 12px;
      span{
        display: inline-block;
        font-weight: bold;
      }
    }
    .ttl{
      margin-top: 4px;
      padding-right: 1.5em;
      font-size: 19px;
      font-weight: bold;
    }
  }
}
/* c-blog-list--s
---------------------------------------------------------------------------*/
.c-blog-list{
  &--s{
    margin-top: -40px;
  }
  &--s &__item{
    width: 33.33%;
    margin-top: 40px;
  }
  &--s &__item-txt{
    margin-top: 10px;
    .img{
      width: 32px;
      margin-right: 8px;
    }
    .ttl{
      margin-top: 5px;
      padding-right: 1.5em;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
/* c-blog-list--news
---------------------------------------------------------------------------*/
.c-blog-list{
  &--news &__item-txt{
    margin-top: 27px;
  }
}


@include g.media-sp {
  .c-blog-list{
    ul{
      display: block;
      margin-top: 35px;
    }
    &__item{
      margin-top: 24px;
      width: 100%;
      a{
        background: #FFF;
      }
    }
    &__item-txt{
      margin-top: 0;
      padding: 22px 20px 24px;
      .txt-wrap{
        line-height: 1.4;
      }
      .img{
        width: 40px;
        margin-right: 9px;
      }
      .name,
      .view{
        display: block;
      }
      .date{
        font-size: 12px;
        line-height: 1.1;
      }
      .ttl{
        margin-top: 10px;
        font-size: 14px;
        padding: 0;
        line-height: 1.57;
      }
    }
    &__item-img .frame img{
      transition: all 600ms g.$ease-base;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    a:active &__item-img .frame img,
    a:hover &__item-img .frame img{
      -webkit-filter: none;
      filter: none;
    }
    &__item-img .thumb-svg{
      display: none;
    }
  }
  /* c-blog-list--s
  ---------------------------------------------------------------------------*/
  .c-blog-list{
    &--s {
      margin-top: 0;
    }
    &--s &__item{
      width: 100%;
      margin-top: 25px;
    }
    &--s  &__item-txt{
      margin-top: 0;
      .date{
        font-size: 12px;
      }
      .ttl{
        margin-top: 10px;
        font-size: 14px;
        padding: 0;
      }
    }
  }
  /* c-blog-list--news
  ---------------------------------------------------------------------------*/
  .c-blog-list{
    &--news &__item-txt{
      padding-top: 30px;
    }
    &--news &__item-txt{
      .ttl{
        margin-top: 5px;
      }
    }
  }
}


/*--------------------------------------------------------------------------
  c-sub-nav
---------------------------------------------------------------------------*/
.c-sub-nav{
  font-size: 12px;
  font-weight: bold;
  /* tp */
  &__tp{
    margin-top: 80px;
  }
  /* bt */
  &__bt{
    margin-top: 100px;
  }
  /* list */
  &__list{
    @include g.inline-style();
    li{
      padding-right: 16px;
      &:not(.active) a{
        color: #888;
      }
      &:after{
        content: '|';
        font-weight: normal;
        color: #888;
      }
      a{
       transition: all 300ms g.$ease-base;
        margin-right: 16px;
      }
      &:last-child{
        padding-right: 0;
        &:after{
          content: none;
        }
      }
      &.active a,
      a:hover{
        color: g.$color-black;
      }
    }
  }
  /* bt */
  &__bt &__inner{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
   }
  &__bt &__ttl{
    padding-top: 3px;
    padding-right: 40px;
    font-family: g.$tradeGothic;
    font-size: 14px;
    line-height: 12px;
  }
  &__bt &__list{
  }
}

@include g.media-sp {
  .c-sub-nav{
    &__tp{
      display: none;
    }
    /* bt */
    &__bt{
      margin-top: 40px;
    }
    &__bt &__list{
      margin-right: -5px;
      font-weight: normal;
      li{
        margin-top: 5px;
        padding-right: 5px;
        font-size: 12px;
        a{
          display: inline-block;
          border-radius: 2px;
          margin-right: 0;
          padding: 7px 10px;
          color: g.$color-black;
          background: #eee;
        }
        &:after{
          content: none;
        }
      }
    }
    &__bt &__ttl{
      margin-bottom: 3px;
      padding-top: 0;
      font-size: 16px;
    }
  }
}


/*--------------------------------------------------------------------------
  c-service-list
---------------------------------------------------------------------------*/

.c-service-list{
  @include g.flex-style01();
  &__item{
    margin-top: 95px;
    width: 50%;
    a{
      display: block;
    }
  }
  &__item a:hover &__item-img,
  &__item a:active &__item-img {
    .img-svg{
      opacity: 0;
    }
  }
  &__item-img{
    overflow: hidden;
    position: relative;
    .img-svg{
      transition: all 400ms g.$ease-base;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      image{
        display: block;
        width: 100%;
        height: auto;
        vertical-align: top;
        filter: url(#grayscale);
        backface-visibility: hidden;
        position: relative;
      }
    }
    .frame{
      display: block;
      img{
        transition: transform 20000ms cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }
  }
  &__item-txt{
    margin-top: 20px;
    line-height: 1.3;
    .ttl{
      font-size: 21px;
      font-weight: bold;
    }
    .txt{
      margin-top: 7px;
      font-size: 13px;
      color: #888;
    }
  }
}

@include g.media-sp {
  .c-service-list{
    margin-top: 10px;
    &__item{
      margin-top: 20px;
      width: 100%;
    }
    &__item-txt{
      margin: 0;
      padding: 27px 20px;
      background: #FFF;
      line-height: 1.57;
      .ttl{
        font-size: 14px;
      }
      .txt{
        margin-top: 2px;
        font-size: 12px;
      }
    }
    &__item-img .frame img{
      transition: all 600ms g.$ease-base;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    a:active &__item-img .frame img,
    a:hover &__item-img .frame img{
      -webkit-filter: none;
      filter: none;
    }
    .img-svg{
      display: none;
    }
  }
}


/*--------------------------------------------------------------------------
  c-sub-nav
---------------------------------------------------------------------------*/
.c-capability-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px -10px 0;
  &__item{
    width: 33.33%;
    margin: 20px 0 0;
    padding: 0 10px;
    a,
    .inner{
      transition: all 400ms g.$ease-base;
      position: relative;
      display: block;
      background-color: #FFF;
      height: 100%;
      padding: 238px 42px 105px;
    }
    path{
     transition: all 200ms g.$ease-base;
    }
    .ttl{
      font-size: 21px;
    }
    .ico{
      transition: all 400ms g.$ease-base;
      display: block;
      position: absolute;
      top: 60px;
      left: 0;
      text-align: center;
      width: 100%;
      .svg-ico,
      img{
        width: auto;
        height: 120px;
     }
      .obj-wrap{
      }
    }
    .txt{
      margin-top: 2px;
      font-size: 14px;
    }
    .more{
      position: absolute;
      bottom: 55px;
      left: 42px;
      margin-top: 20px;
      font-size: 14px;
      font-weight: bold;
      span{
        position: relative;
        display: inline-block;
        padding-right: 30px;
        line-height: 20px;
      }
      svg.ico-arw{
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
        width: 20px;
        height: 20px;
        path,rect{
         transition: all 400ms g.$ease-base;
        }
      }
    }
  }
}

.c-capability-list{
  &--type02 &__item{
    width: 50%;
    .inner{
      padding: 200px 42px 35px;
      text-align: center;
    }
  }
}

@include g.media-pc {
  .c-capability-list{
    &__item{
      a:hover{
        background-color: g.$color-black;
        color: #FFF;
        .more .ico-arw path,
        .ico path{
          fill: #FFF;
        }
        .more .ico-arw rect{
          stroke: #FFF;
        }
      }
    }
  }
}

@include g.media-sp {
  .c-capability-list{
    margin: 15px 0 0;
    &__item{
      width: 100%;
      padding: 0;
      a{
        padding: 120px 25px 25px;
        text-align: center;
      }
      .ttl{
        font-size: 18px;
      }
      .ico{
        top: 30px;
        .svg-ico,
        img{
          width: auto;
          height: 70px;
        }
      }
      .txt{
        margin-top: 2px;
        font-size: 13px;
        line-height: 1.53;
      }
      .more{
        position: static;
        margin-top: 12px;
        font-size: 12px;
        font-weight: normal;
        span{
          padding-right: 20px;
          line-height: 16px;
        }
        svg.ico-arw{
          margin-top: -8px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .c-capability-list{
    &--type02 &__item{
      width: 100%;
      margin-top: 10px;
      .inner{
        padding: 114px 25px 26px;
      }
      .txt{
        margin-top: 4px;
      }
    }
  }
}
