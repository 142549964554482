@use "../import/common" as g;

/* *
 *
 *  index.css
 *
 *
 * */


/*--------------------------------------------------------------------------
  sec
---------------------------------------------------------------------------*/
.page-case-index{
  .lead{
    margin-top: 37px;
  }
  @include g.media-sp {
    .lead{
      margin-top: 18px;
    }
  }
}
