@use "../import/common" as g;

/* *
 *
 *  index.css
 *
 *
 * */

/*--------------------------------------------------------------------------
  sec
---------------------------------------------------------------------------*/
.page-corporate-index{
  .sec{
    .catch{
      margin-top: 40px;
      img{
        max-width: 100%;
      }
    }
    .lead{
      margin-top: 80px;
      line-height: 1.95;
      font-size: 21px;
      .txt{
        margin-top: 50px;
        letter-spacing: 0.1rem;
      }
    }
    .img{
      margin-top: 120px;
    }
  }

  @include g.media-sp {
    .sec{
      .catch{
        margin-top: 33px;
      }
      .lead{
        margin-top: 35px;
        line-height: 1.56;
        font-size: 16px;
        .txt{
         margin-top: 30px;
         letter-spacing: normal;
        }
      }
      .img{
        margin-top: 50px;
      }
    }
  }
}
