@use "../import/common" as g;

/* *
 *
 *  index.css
 *
 *
 * */

/*--------------------------------------------------------------------------
  sec
---------------------------------------------------------------------------*/

.page-service-index{
  .sec{
    padding-bottom: 60px;
    border-bottom: solid 1px g.$color-black;
  }
  .catch{
    margin-top: 60px;
  }
  .lead{
    margin-top: 60px;
    line-height: 2;
    p{
      margin-top: 32px;
    }
  }
}
@media screen and (max-width: g.$pc-break){
  .page-service-index{
    .catch{
      font-size: 7.7vw;
    }
  }
}
@include g.media-sp {
  .page-service-index{
    .sec{
      padding-bottom: 0;
      border-bottom: none;
    }
    .catch{
      margin-top: 52px;
      img{
        width: 71.5%;
      }
    }
    .lead{
      margin-top: 45px;
      p{
        margin-top: 31px;
        line-height: 1.78;
      }
    }
  }
}
