@use "../import/common" as g;

/* *
 *
 *  detail.css
 *
 *
 * */

/*--------------------------------------------------------------------------
  detail pc
---------------------------------------------------------------------------*/
.page-service-detail{
  .sec-service{
    padding-bottom: 97px;
    border-bottom: solid 1px g.$color-black;
    &__kv{
      margin-top: 70px;
      position: relative;
      img{
        width: 100%;
        height: auto;
      }
      .caption{
        position: absolute;
        bottom: 2px;
        right: 9px;
        font-size: 10px;
        color: #FFF;
        font-family: g.$tradeGothic;
      }
    }
    &__inner{
      width: g.$pc-cont-article-width;
      margin: 0 auto;
      line-height: 2;
    }
    &__box{
      margin-top: 80px;
      .ttl{
        margin-bottom: 8px;
        font-size: 24px;
      }
      p{
        .em{
          border-bottom: solid 2px g.$color-black;
          font-weight: bold;
        }
      }

    }
    &__price{
      margin-top: 95px;
      background: #FFF;
      padding: 47px 60px 54px;
      ul{
        margin-top: 16px;
        font-size: 14px;
      }
      .note{
        margin-top: 26px;
        color: g.$color-gray;
        font-size: 12px;
      }

    }
    &__related{
      margin-top: 90px;
      .c-ttl-style01{
        margin-bottom: 10px;
      }
      .item{
        margin-top: 15px;
        a{
          transition: g.$transitionHover;
          display: table;
          width: 100%;
          background: #FFF;
          &:hover,
          &:active{
            color: #FFF;
           // @include animation(btnHoverTxtBlk $duration-btn-hover g.$ease-base);
            .txt-wrap{
              &:before{
                width: 100%;
                //@include animation(btnHoverBg $duration-btn-hover g.$ease-base);
              }
              svg.ico{
                path{
                  fill: #FFF;
                }
                rect{
                  stroke: #FFF;
                }
              }
            }
          }
        }
        .img,
        .txt-wrap{
          display: table-cell;
          vertical-align: middle;
        }
        .img{
          width: 200px;
          img{
            width: 100%;
            height: auto;
          }
        }
        .txt-wrap{
          position: relative;
          width: calc(100% - 200px);
          padding: 0 80px 0 30px;
          line-height: 1.4;
          &:before{
            transition: g.$transitionHover;
            @include g.pseudo($width: 0);
            top: 0;
            left: 0;
            background-color: g.$color-black;
          }
          svg.ico{
            position: absolute;
            top: 50%;
            right: 39px;
            width: 30px;
            height: 30px;
            margin-top: -15px;
            path,
            rect{
              transition: g.$transitionHover;
            }
          }
        }
        .label{
          position: relative;
          color: g.$color-gray;
          font-size: 13px;
          font-weight: normal;
          z-index: 1;
        }
        .catch{
          position: relative;
          margin-top: 2px;
          font-size: 16px;
          font-weight: bold;
          z-index: 1;
        }
      }
    }
  }
}

@include g.media-sp {
   .page-service-detail{
    .sec-service{
      padding-bottom: 40px;
      &__kv{
        margin: 55px -8vw 0;
        .caption{
          bottom: 0;
          right: 3px;
          font-size: 9px;
        }
      }
      &__inner{
        width: 100%;
        line-height: 1.78;
      }
      &__box{
        margin-top: 50px;
        line-height: 1.8;
        &:first-child{
          margin-top: 25px;
        }
        .ttl{
          margin-bottom: 5px;
          font-size: 18px;
        }
      }
      &__price{
        margin-top: 55px;
        padding: 24px 27px;
        line-height: 1.53;
        ul{
          margin-top: 0;
          font-size: 13px;
          li{
            margin-top: 20px;
            .ttl{
              display: block;
            }
          }
        }
        .note{
          margin-top: 36px;
          font-size: 13px;
        }
      }
      &__related{
        margin-top: 55px;
        .ttl{
          margin-bottom: 12px;
        }
        .item{
          margin-top: 5px;
          a:hover{
            color: g.$color-black;
            .label{
              color: g.$color-gray;
            }
            svg.ico{
              path,
              rect{
                animation: none;
              }
            }
          }
         .img{
            width: 100px;
          }
          .txt-wrap{
            width: calc(100% - 100px);
            padding: 0 15px;
            line-height: 1.2;
            &:before{
              content: none;
            }
            svg.ico{
              display: none;
            }
          }
          .label{
            font-size: 11px;
            color: g.$color-gray;
            .inner{
              display: block;
              color: g.$color-black;
            }
          }
          .catch{
            display: none;
            margin: 0;
          }
        }
      }
    }
  }
}
