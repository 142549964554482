@use "../import/common" as g;

/* *
 *
 *  index.css
 *
 *
 * */

.page-index{

  /*--------------------------------------------------------------------------
    overwrite
  ---------------------------------------------------------------------------*/
  @include g.media-pc {
    .global-header{
      .global-nav,
      .global-header__contact{
        opacity: 0;
        transform: translateY(-30%);
      }
      &._load{
        .global-nav,
        .global-header__contact{
          transform: none;
          transition: opacity 400ms g.$ease-base 600ms;
          opacity: 1;
        }
      }
    }
  }

  /*--------------------------------------------------------------------------
    mv
  ---------------------------------------------------------------------------*/
  .mv{
    padding: 120px 0 107px;
    padding: 8.3vw 0 7.9vw;
    &__inner{
      padding: 0 14.5%;
    }
    $kvDuration : 400ms;
    $kvTranslateY: translateY(100px);
    &._load{
      .mv__catch,
      .mv__lead{
        .line {
          .inner{
            opacity: 1;
            transform: translateY(0);
          }
        }
        .line02 .inner,
        .line06 .inner{
          transition-delay: 50ms;
        }
        .line04 .inner{
          transition-delay: 100ms;
        }
      }
      .mv__btn-wrap{
        .btn{
          transition: all $kvDuration cubic-bezier(0.075, 0.82, 0.165, 1);
          opacity: 1;
          transform: translateX(0);
          transition-delay: 600ms;
        }
      }
    }
    &__catch,
    &__lead{
      @include g.media-pc {
        .u-for-sp{
          display: none !important;
        }
      }
      .line{
        overflow: hidden;
        display: block;
        position: relative;
        .inner{
          backface-visibility: hidden;
          transition: all $kvDuration cubic-bezier(0.075, 0.82, 0.165, 1);
          display: block;
          opacity: 0;
          transform: $kvTranslateY;
        }
      }
    }
    &__catch{
      .line01{
        display: block;
        img{
          display: inline-block;
          height: auto;
        }
        img.img01{
          width: g.vw(375, 1440);
          max-width: 375px;
        }
        img.img02{
          width: g.vw(422, 1440);
          max-width: 422px;
          margin-left: 48px;
          @include g.media-pc-break {
            margin-left: g.vw(48, 1440);
          }
        }
      }
      .line02{
        display: block;
        margin-top: 36px;
        @include g.media-pc-break {
          margin-top: g.vw(36, 1440);
        }
        img{
          display: inline-block;
          height: auto;
        }
        img.img01{
          width: g.vw(265, 1440);
          max-width: 265px;
        }
        img.img02{
          width: g.vw(313, 1440);
          max-width: 313px;
          margin-left: 43px;
          @include g.media-pc-break {
            margin-left: g.vw(43, 1440);
          }
        }
      }
      @include g.media-pc {
        .line03{
          display: none;
        }
      }
    }
    &__lead{
      font-size: 20px;
      font-size: 1.4vw;
      font-weight: bold;
      line-height: 1;
      .line05{
        display: block;
        margin-top: 48px;
        @include g.media-pc-break {
          margin-top: g.vw(48, 1440);
        }
        img{
          width: g.vw(448, 1440);
          max-width: 448px;
          height: auto;
        }
      }
      .line06{
        display: block;
        margin-top: 20px;
        @include g.media-pc-break {
          margin-top: g.vw(20, 1440);
        }
        img{
          width: g.vw(501, 1440);
          max-width: 501px;
          height: auto;
        }
      }
    }
    &__btn-wrap{
      overflow: hidden;
      margin-top: 38px;
      @include g.media-pc {
        .u-for-pc{
          display: inline-block !important;
        }
      }
      @include g.media-pc-break {
        margin-top: g.vw(38, 1440);
      }
      .btn{
        display: inline-block;
        opacity: 0;
        padding: 0 8px 8px 0;
        transform: $kvTranslateY;
        @include g.media-pc {
          &:before, a{
            width: 340px;
          }
        }
      }
    }
  }
  @include g.media-sp {
    .mv{
      padding: 0;
      &._load{
        .mv__catch,
        .mv__lead{
          .line02:before{
            animation-delay: 50ms;
          }
          .line03:before{
            animation-delay: 100ms;
          }
          .line04:before{
            animation-delay: 150ms;
          }
          .line05:before{
            animation-delay: 200ms;
          }
        }
      }
      &__inner{
        position: relative;
        min-height: 89vh;
        padding: 0;
      }
      &__txt-wrap{
        position: absolute;
        top: 13.8%;
        padding: 0 g.$sp-space;
      }
      &__catch{
        width: 100%;
        line-height: 0;
        .u-for-pc{
          display: none !important;
        }
        .line{
          width: 100%;
          & + .line{
            margin-top: 3.2%;
          }
        }
        .line img{
          width: 100%;
          height: auto;
        }
        .line01{
          img.img01{
            width: g.vw(386);
            max-width: none;
          }
        }
        .line02{
          img{
            width: g.vw(436);
            max-width: none;
          }
        }
        .line03{
          img{
            width: g.vw(268);
            max-width: none;
          }
        }
        .line04{
          img{
            width: g.vw(316);
            max-width: none;
          }
        }
        img{
          width: 100%;
        }
      }
      &__lead{
        margin-top: 8.5%;
        .u-for-pc{
          display: none !important;
        }
        img{
          display: block;
        }
        .line05{
          img{
            width: g.vw(518);
            max-width: none;
            margin: 0;
          }
        }
        .line06{
          img{
            width: g.vw(406);
            max-width: none;
            margin-top: 4.8%;
          }
        }
      }
      &__btn-wrap{
        margin-top: 10.8%;
        .btn{
          display: block;
          padding: 0 8px 8px 0;
          a{
            font-weight: normal;
          }
          &:before, a{
            width: g.vw(630);
          }
        }
      }
    }
  }

@media screen and (orientation: landscape) and (max-width: g.$sp){
  .mv__inner{
    min-height: 500px;
  }
}


  /*--------------------------------------------------------------------------
    sec
  ---------------------------------------------------------------------------*/
  .sec{
    &__ttl{
      font-size: 19px;
      font-weight: bold;
      font-family: g.$tradeGothic;
      span{
        position: relative;
        display: inline-block;
        padding-right: 43px;
        line-height: 1;
        &:before{
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          width: 32px;
          height: 0;
          margin-top: -3px;
          border-top: solid 2px g.$color-black;
        }
      }
    }
  }

  @include g.media-sp {
    .sec{
      &__ttl{
        span{
          padding-right: 40px;
        }
      }
    }
  }

  /*--------------------------------------------------------------------------
    sec-case
  ---------------------------------------------------------------------------*/
  .sec-case{
    &__btn{
      margin-top: 20px;
      @include g.btn-style($width:100%, $padding:47px 40px,$space: 53px);
      a{
        text-align: right;
      }
    }
    $delay-base: 600ms;
    $caseItemTransition: all 500ms g.$ease-base;
    .p-case-list-index{
      .item{
        overflow: hidden;
        transition: $caseItemTransition;
        &:after{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background: g.$color-black;
        }
        a{
          transition: all 100ms g.$ease-base;
          width: 0;
        }
        &:nth-child(1){
          transform: translateX(0);
        }
        &:nth-child(2){
          transform: translateX(-17.3%);
        }
        &:nth-child(3){
          transform: translateX(-33.6%);
        }
      }
      &._load .item{
          transform: translateX(0%);
          opacity: 1;
         &:after{
          animation-timing-function: g.$ease-base;
          animation-duration: 800ms;
          animation-name: indexCasesTween;
          animation-fill-mode: forwards;
         }
        a{
          width: 100%;
        }
        &:nth-child(1){
         a,
         &:after{
            transition-delay: $delay-base + 300ms;
            animation-delay: $delay-base;
          }
        }
        &:nth-child(2){
          a,
          &:after{
            transition-delay: $delay-base + 400ms;
            animation-delay: $delay-base + 100ms;
          }
        }
        &:nth-child(3){
          a,
          &:after{
            transition-delay: $delay-base + 500ms;
            animation-delay: $delay-base + 200ms;
          }
        }
      }
    }
  }

  @include g.media-sp {
    .sec-case{
      .p-case-list-index{
        .item{
          a{
            overflow: hidden;
          }
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3){
            transform: translateX(0);
          }
        }
      }
      &__btn{
        margin-top: 0;
        @include g.btn-style($width:100%, $padding: 27px 30px,$icoSize: 20px,$space : 35px);
        a{
          text-align: left;
          font-weight: normal;
          .inner{
            display: block;
          }
          &:before{
            content: none;
          }
          &:hover{
            color: #FFF;
            svg.ico{
              path{
                fill: #FFF;
              }
              rect{
                stroke: #FFF;
              }
            }
          }
        }
        &:before{
          content: none;
        }
      }
    }
  }

@keyframes indexCasesTween {
  0%{
    transition-timing-function: g.$ease-base;
    left: 0;
    right: auto;
    width: 0;
  }
  30% {
    transition-timing-function: g.$ease-base;
    width: 100%;
    left: 0;
    right: auto;
  }
  34% {
    transition-timing-function: g.$ease-base;
    width: 100%;
    right: 0;
    left: auto;
  }
  100% {
    transition-timing-function: g.$ease-base;
    width: 0;
    right: 0;
    left: auto;
  }
}


  /*--------------------------------------------------------------------------
    sec-client
  ---------------------------------------------------------------------------*/
  .sec-client{
    padding: 150px 0 160px;
    &__list{
      display: flex;
      margin: -10px -5% 0;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      flex-flow: row wrap;
      &:last-child{
        justify-content: flex-start;
        li{

        }
      }
      li{
        width: 25%;
        margin-top: 60px;
        line-height: 0;
        text-align: center;
        img{
          height: auto;
        }
        &.nikon{
          padding-right: 40px;
        }
        &.baycrews{
          padding-left: 15px;
        }
        &.nikoand{
          padding-right: 40px;
        }
        &.dena{
          padding-right: 10px;
        }
        &.mizuno{
          padding-left: 10px;
        }
        &.unitedarrows{
          padding-left: 45px;
        }
        &.visional{
          padding-left: 43px;
        }
        // &.visional{
        //   padding-left: 43px;
        // }

        // &:nth-child(5n+1){
        //   text-align: left;
        // }
        // &:nth-child(5n){
        //   text-align: right;
        // }
        // &:last-child{
        //    margin-left: auto;
        //   margin-right: 20%;
        // }
      }
    }
  }

  @media screen and (max-width: 1086px){
    .sec-client{
      &__list{
        li{
          flex-flow: row wrap;
          width: 33.33%;
          padding: 0!important;
          text-align: center;
        }
      }
    }
  }

  @include g.media-sp {
    .sec-client{
      padding: 68px 0 49px;
      &__list{
        margin: 6px -7% 0;
        &:last-child{
          // justify-content: space-between;
          li{

          }
        }
        li{
          width: 33.33%;
          margin: 26px 0;
          img{
            width: 75px;
          }
        }
      }
    }
  }

  /*--------------------------------------------------------------------------
    sec-service
  ---------------------------------------------------------------------------*/
  .sec-service{
    padding: 110px 0 120px;
    background: url(/assets/images/index/bg_service_pc.jpg) no-repeat 50% 50%;
    background-size: cover;
    &__catch{
      margin-top: 54px;
      img{
        width: g.vw(1019,1440);
        max-width: 1019px;
        height: auto;
      }
    }
    &__lead{
      margin-top: 34px;
      font-size: 16px;
      font-weight: bold;
      // width: g.vw(1212,2880);
      // max-width: 606px;
      line-height: 1.8;
    }
    &__btn{
      margin-top: 35px;
      &:before{
        width: 340px;
      }
      a{
        width: 340px;
      }
    }
  }

  @include g.media-sp {
    .sec-service{
      padding: 52px 0 52px;
      background: url(/assets/images/index/bg_service_sp.jpg) no-repeat 50% 50%;
      background-size: cover;
      &__catch{
        margin-top: 19px;
        width: g.vw(598);
        img{
          width: 100%;
          height: auto;
        }
      }
      &__lead{
        width: 100%;
        margin-top: 22px;
        font-size: 14px;
        letter-spacing: -0.03em;
        font-weight: normal;
        line-height: 2;
      }
      &__btn{
        margin-top: 27px;
        &:before{
          width: 100%;
        }
        a{
          width: 100%;
          font-weight: normal;
        }
      }
    }
  }


  /*--------------------------------------------------------------------------
    sec-blog
  ---------------------------------------------------------------------------*/
  .sec-blog{
    padding: 95px 0 110px;
    &__catch{
      margin-top: 47px;
      line-height: 1;
      width: g.vw(496,1440);
      max-width: 496px;
      margin-bottom: 24px;
    }
    &__txt{
      font-weight: bold;
      line-height: 1.8;
    }
    &__list{
      margin-top: 60px;
    }
  }

  @include g.media-sp {
    .sec-blog{
      padding: 53px 0 30px;
      &__catch{
        margin-top: 18px;
        margin-bottom: 0;
        font-size: 49px;
        width: g.vw(608);
      }
      &__txt{
        margin-top: 12px;
        line-height: 2;
        font-weight: normal;
      }
      &__list{
        margin-top: 40px;
      }
    }
  }
}
