@use "../import/common" as g;

/* *
 *
 *  blog article
 *
 *
 * */


/*--------------------------------------------------------------------------
  article
---------------------------------------------------------------------------*/

.article{
  &__head{
    .ttl{
      font-size: 32px;
      font-weight: bold;
    }
    .info{
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 12px;
      color: g.$color-gray;
      font-family: g.$tradeGothic;
    }
    .img{
      padding-right: 20px;
      img{
        width: 40px;
      }
    }
    .txt-wrap{
      letter-spacing: -0.4em;
      p{
        display: inline-block;
        letter-spacing: normal;
      }
      .name{
        padding-right: 5px;
        &:after{
          content: "/";
          padding-left: 5px;
        }
      }
    }
  }
  &__kv{
    margin-top: 40px;
  }
  &__share{
    margin: 55px auto 0;
    width: g.$pc-cont-article-width;
    ul{
      @include g.inline-style();
    }
    li{
      padding-right: 28px;
      a{
        display: block;
      }
      i{
        font-size: 20px;
      }
      &.tw{
        span{
          position: relative;
          top: 1px;
          display: block;
          width: 18px;
          height: 18px;
          background: url(/assets/images/common/x-twitter.svg) no-repeat 0 100%;
          background-size: 18px auto;
        }
      }
      &.hatena{
        span{
          display: block;
          width: 18px;
          height: 20px;
          background: url(/assets/images/common/ico_hatena.svg) no-repeat 0 100%;
          background-size: 18px auto;
        }
      }
    }
  }
  &__pager{
    position: relative;
    margin-top: 80px;
    border-top: solid 1px g.$color-black;
    border-bottom: solid 1px g.$color-black;
    padding: 40px 0;
    font-size: 12px;
    li + li{
      margin-top: 10px;
    }
    li{
      padding-right: 5em;
    }
    .all{
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -1em;
    }
  }
  &__cont{
    /* cv-area */
    .cv-area{
      width: g.$pc-cont-article-width;
      margin: 60px auto 0;
      border: solid 4px g.$color-black;
      padding: 60px;
      p{
        margin: 0;
      }
      ul{
        margin-top: 30px;
        @include g.flex-style01();
        li{
          a{
            &:before{
              background-color: g.$color-red;
            }
            &:hover{
              color: #FFF;
              animation: none;
              > span{
                animation: none;
              }
              svg.ico{
                path{
                 fill: #FFF;
                }
                rect{
                  stroke: #FFF;
                }
              }
            }
          }
        }
      }
    }
    /* quote */
    .quote{
      width: g.$pc-cont-article-width;
      margin: 50px auto 0;
      padding-left: 1.6em;
      text-indent: -1.6em;
      font-size: 12px;
      li a{
        padding-right: 17px;
        background: url(/assets/images/common/ico_blank.svg) no-repeat 100% 50%;
        background-size: 10px auto;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    /* wp */
    &.blog_cont{
      .box{
        font-size: 18px;
        letter-spacing: .05em;
      }
    }
    .box{
      width: g.$pc-cont-article-width;
      margin: 70px auto 0;
      line-height: 2;
      &.line-top{
        position: relative;
        padding-top: 75px;
        &:before{
          @include g.pseudo(100px,0);
          top: 0;
          left: 0;
          border-top: solid 2px g.$color-black;
        }
      }
      .no-sp{
        margin-top: 0;
      }
      .em{
        font-weight: bold;
      }
      a{
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
      }
    }
    h2{
      font-size: 24px;
      font-weight: bold;
    }
    h3{
      margin-top: 30px;
      font-weight: bold;
    }
    h4{
      margin-top: 30px;
      font-weight: normal;
      &:before{
        content: '【';
      }
      &:after{
        content: '】';
      }
    }
    p{
      margin-top: 25px;
      .small{
        font-size: 80%;
        line-height: 2;
        display: block;
      }
    }
    h3 +p,
    h4 +p{
      margin-top: 5px;
    }
    p.no-sp{
      margin-top: 0;
    }
    .low{
      margin: 30px 0 30px 40px;
    }
    .well{
      background: g.$color-bg-gray;
      margin-top: 77px;
      padding: 39px 40px 42px;
      line-height: 1.75;
      h3{
        margin-top: 0;
        margin-bottom: 18px;
        line-height: 1;
      }
      ul{
        li{
          position: relative;
          padding-left: 1.2em;
          &::before{
            content: '・';
            display: inline-block;
            position: absolute;
            top: 0;
            left: -1px;
          }
          & + li{
            margin-top: 5px;
          }
        }
      }
    }
    .img{
      margin-top: 70px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .box > .img:first-child{
      margin-top: 0;
    }
    h2 + .img,
    p + .img,
    .box + .img{
      margin-top: 40px;
    }
    .img + h3,
    .img + h4{
      margin-top: 50px;
    }
  }
}

@include g.media-sp {
  .article{
    &__head{
      .ttl{
        font-size: 21px;
        line-height: 1.42;
      }
      .info{
        margin-top: 12px;
      }
      .img{
        padding-right: 9px;
      }
      .txt-wrap{
        line-height: 1.4;
        p{
          display: block;
        }
        .name{
          padding-left: 0;
           &:after{
             content: none;
          }
        }
      }
    }
    &__inner{
      width: 100%;
    }
    &__kv{
      margin: 25px -8vw 0;
    }
    &__share{
      margin-top: 40px;
      width: 100%;
        li{
        padding-right: 17px;
      }
    }
    &__pager{
      margin-top: 32px;
      padding: 26px 0;
      li + li{
        margin-top: 10px;
      }
      li{
        padding-right: 5em;
      }
      .prev,
      .next{

      }
      .all{
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -1em;
      }
    }
    &__cont{
      /* cv-area */
      .cv-area{
        border: solid 4px g.$color-black;
        width: 100%;
        margin-top: 32px;
        padding: 20px 25px 30px;
        p{
          margin: 0;
        }
        ul{
          margin-top: 23px;
          @include g.flex-style01();
          li{
            width: 100%;
            & + li{
              margin-top: 15px;
            }
          }
        }
      }
      /* quote */
      .quote{
        width: 100%;
        margin-top: 36px;
        font-size: 12px;
        line-height: 1.5;
        li{
          margin-top: 10px;
          a{
            padding-right: 11px;
          }
        }
      }
      /* wp */
      &.blog_cont{
        .box{
          font-size: 16px;
        }
      }
      .box{
        margin-top: 37px;
        width: 100%;
        line-height: 1.8;
        &.line-top{
          margin-top: 32px;
          padding-top: 37px;
          &:before{
            width: 60px;
          }
        }
        .no-sp{
          margin-top: 0;
        }
        .em{
          font-weight: bold;
        }
      }
      h2{
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
      }

      h3, h4{
        margin-top: 20px;
      }
      p{
        margin-top: 25px;
      }
      h3 +p{
        margin-top: 0;
      }
      h2 + p{
        margin-top: 8px;
      }
      .low{
        margin: 20px 0 20px 20px;
      }
      .well{
        margin-top: 30px;
        padding: 30px 30px 32px;
        line-height: 1.5;
        h3{
          margin-top: 0;
          margin-bottom: 14px;
        }
        ul{
          li{
            padding-left: 1.5em;
            &::before{
              left: 1px;
            }
            & + li{
              margin-top: 9px;
            }
          }
        }
      }
      .img{
        margin-top: 33px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .box > .img:first-child{
        margin-top: 0;
      }
      h2 + .img,
      p + .img,
      .box + .img{
        margin-top: 20px;
      }
      .img + h3,
      .img + h4{
        margin-top: 20px;
      }
    }
  }
}
